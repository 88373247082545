import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import * as React from 'react';
import API from '../../common/API';
import store from '../../store';

const styles = (theme: Theme) => createStyles({
  content: {
    [theme.breakpoints.up('sm')]: {
      width: '32em'
    }
  },
  label: {
    marginBottom: "-10px",
    display: "block",
    color: "#BEBEBE",
  },
  input: {
    background: "#F5F7FB",
    border: 0,
    borderRadius: "5px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f5f5f5"
    }
  },
  labelSelect: {
    marginTop: "-15px",
    display: "block",
    color: "#BEBEBE",
  },
  select: {
    marginTop: '5px',
    background: "#F5F7FB",
    borderRadius: "5px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f5f5f5"
    }
  }
});

interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: (refresh: boolean) => () => void;
  recordId: number;
}

interface IState {
  address_name?: string;
  address_zip?: string;
  address_state?: string;
  address_city?: string;
  address_street?: string;
  address_building?: string;
  tel?: string;
  errors?: {
    address_name?: string;
    address_zip?: string;
    address_state?: string;
    address_city?: string;
    address_street?: string;
    address_building?: string;
    tel?: string;
  }
  prefs?: Array<{pref_code: string, pref_name: string}>
}

class DeliveryDialog extends React.Component<IProps, IState> {
  public state = {
    address_name: '',
    address_zip: '',
    address_state: '',
    address_city: '',
    address_street: '',
    address_building: '',
    tel: '',
    errors: {
      address_name: '',
      address_zip: '',
      address_state: '',
      address_city: '',
      address_street: '',
      address_building: '',
      tel: '',
    },
    prefs: [{pref_code: '0', pref_name: ''}],
  }

  public componentWillMount() {
    API.getPref().then(prefs => {
      this.setState({
        prefs
      });
    });
  }

  public render() {
    const { classes, onClose, recordId, ...other } = this.props;
    return (
      <Dialog onEnter={this.handleInit} onClose={onClose(false)} aria-labelledby="delivery-dialog-title" {...other}>
        {/*<DialogTitle id="delivery-dialog-title">お届け先の入力</DialogTitle>*/}
        <DialogContent className={classes.content}>
          <Typography variant="caption" style={{fontSize: "20px", fontWeight: 600}}>
            お届け先の入力
          </Typography>
          <br/>
          <Typography variant="caption" style={{color: "#BEBEBE"}}>
            ※発送は日本国内のみとなります。
          </Typography>
          <br/>
          <br/>
          <label className={classes.label}>*氏名</label>
          <TextField
            variant={"outlined"}
            size={"small"}
            className={classes.input}
            autoFocus={true}
            margin="normal"
            id="address_name"
            // label="*氏名"
            type="text"
            fullWidth={true}
            value={this.state.address_name}
            error={this.state.errors.address_name !== ''}
            onChange={this.handleChangeField('address_name')}
          />
          <FormHelperText error={true} component="span">{this.state.errors.address_name}</FormHelperText>
          <label className={classes.label}>*郵便番号</label>
          <TextField
            variant={"outlined"}
            size={"small"}
            className={classes.input}
            margin="normal"
            id="address_zip"
            // label="*郵便番号"
            type="text"
            fullWidth={true}
            value={this.state.address_zip}
            error={this.state.errors.address_zip !== ''}
            onChange={this.handleChangeField('address_zip')}
          />
          <FormHelperText error={true} component="span">{this.state.errors.address_zip}</FormHelperText>
          <FormControl margin="normal" fullWidth={true} size="small">
            <label className={classes.labelSelect}>*都道府県</label>
            <Select
              variant={"outlined"}
              className={classes.select}
              inputProps={{
                id: 'address_state'
              }}
              error={this.state.errors.address_state !== ''}
              value={this.state.address_state}
              onChange={this.handleChangeSelect('address_state')}
            >
              {this.state.prefs.map(pref => <MenuItem key={pref.pref_code} value={pref.pref_name}>{pref.pref_name}</MenuItem>)}
            </Select>
          </FormControl>
          <FormHelperText error={true} component="span">{this.state.errors.address_state}</FormHelperText>
          <label className={classes.label}>*市区町村</label>
          <TextField
            variant={"outlined"}
            size={"small"}
            className={classes.input}
            margin="normal"
            id="address_city"
            // label="*市区町村"
            type="text"
            fullWidth={true}
            value={this.state.address_city}
            error={this.state.errors.address_city !== ''}
            onChange={this.handleChangeField('address_city')}
          />
          <FormHelperText error={true} component="span">{this.state.errors.address_city}</FormHelperText>
          <label className={classes.label}>*住所</label>
          <TextField
            variant={"outlined"}
            size={"small"}
            className={classes.input}
            margin="normal"
            id="address_street"
            // label="*住所"
            type="text"
            fullWidth={true}
            value={this.state.address_street}
            error={this.state.errors.address_street !== ''}
            onChange={this.handleChangeField('address_street')}
          />
          <FormHelperText error={true} component="span">{this.state.errors.address_street}</FormHelperText>
          <label className={classes.label}>*ビル建物</label>
          <TextField
            variant={"outlined"}
            size={"small"}
            className={classes.input}
            margin="normal"
            id="address_building"
            // label="ビル建物"
            type="text"
            fullWidth={true}
            value={this.state.address_building}
            error={this.state.errors.address_building !== ''}
            onChange={this.handleChangeField('address_building')}
          />
          <FormHelperText error={true} component="span">{this.state.errors.address_building}</FormHelperText>
          <label className={classes.label}>*電話番号</label>
          <TextField
            variant={"outlined"}
            size={"small"}
            className={classes.input}
            margin="normal"
            id="tel"
            // label="*電話番号"
            type="text"
            fullWidth={true}
            value={this.state.tel}
            error={this.state.errors.tel !== ''}
            onChange={this.handleChangeField('tel')}
          />
          <FormHelperText error={true} component="span">{this.state.errors.tel}</FormHelperText>
        </DialogContent>
        <DialogActions style={{justifyContent: "center"}}>
          <Button variant="text" color="default" onClick={this.props.onClose(false)}
          style={{color: "#8CC358"}}>キャンセル</Button>
          <Button variant="contained" color="primary" onClick={this.handleSubmit} style={{minWidth: "100px"}}>
            {this.props.recordId === 0 ? ('追加') : ('更新')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private handleInit = () => {
    const e = {
      address_name: '',
      address_zip: '',
      address_state: '',
      address_city: '',
      address_street: '',
      address_building: '',
      tel: '',
    }

    if (this.props.recordId === 0) {
      if (store.deliveries.length === 0) {
        const user = store.user;
        this.setState({
          address_name: user.last_name + ' ' + user.first_name,
          address_zip: user.address_zip,
          address_state: user.address_state,
          address_city: user.address_city,
          address_building: user.address_building,
          address_street: user.address_street,
          tel: user.tel,
          errors: e
        })
      } else {
        this.setState({
          errors: e
        })
      }
    } else {
      const address = store.getDelivery(this.props.recordId);
      if (address) {
        this.setState({
          address_name: address.address_name,
          address_zip: address.address_zip,
          address_state: address.address_state,
          address_city: address.address_city,
          address_building: address.address_building,
          address_street: address.address_street,
          tel: address.tel,
          errors: e
        })
      }
    }
  }

  private handleChangeField = (field: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [field]: ev.target.value
    });
  }

  private handleChangeSelect = (field: string) => (ev: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      [field]: ev.target.value
    })
  }

  private handleSubmit = () => {
    (async() => {
      let errors = [];
      if (this.props.recordId === 0) {
        errors = await API.newDelivery(store.accessToken as string, this.state);
      } else {
        errors = await API.updateDelivery(store.accessToken as string, this.props.recordId, this.state);
      }
      if (errors.length === 0) {
        this.props.onClose(true)();
      } else {
        const e = {
          address_name: '',
          address_zip: '',
          address_state: '',
          address_city: '',
          address_street: '',
          address_building: '',
          tel: '',
        }
        errors.forEach((error) => {
          // @ts-ignore
          e[error.field] = error.message;
        });
        this.setState({
          errors: e
        });
      }
    })();
  }
}

export default withStyles(styles)(DeliveryDialog);
