import React, {useEffect, useState} from 'react';
import {Avatar, Card, CardContent, Grid, Popover} from "@material-ui/core";
import Link from '@material-ui/core/Link'
import AccountCircle from '@material-ui/icons/AccountCircle'
import {useGlobal} from "reactn";
import {makeStyles} from "@material-ui/core/styles";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {IS_PRODUCTION} from '../../config/constant';
import store from "../../store";

const useStyles = makeStyles(theme => ({
  cardContent: {
    justifyContent: 'space-around',
    padding: 8,
    alignItems: "center"
  },
  ListItem: {
    padding: 5,
    background: "#f1f1f1",
    borderRadius: 6
  },
  avatar: {
    width: 50,
    height: 50,
    display: "inline-block",
  },
  divRoot: {
    marginTop: 10,
    "& .ant-btn": {
      borderRadius: 15
    }
  },
  link: {
    color: "white"
  },
  button1: {
    padding: 5
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardActionButton: {
    padding: 5
  },
  antBtn: {
    background: `${theme.props.primaryColor} !important`,
    borderColor: `${theme.props.primaryColor} !important`,
    textAlign: "left",
    color: "#fff",
    textTransform: "uppercase",
    display: "flex",
    margin: "0px!important",
    "& svg": {
      fill: theme.props.primaryColor,
    },
    "& .ant-btn-block": {
      width: "97%"
    },
    "& .clf-icon-Btn": {
      left: "5px !important"
    }
  },
  name: {
    fontSize: 12,
    "& .MuiListItemText-primary": {
      fontWeight: 700
    },
    "& .MuiListItemText-secondary": {
      fontWeight: 400
    }
  },
  tabsBox: {
    background: "#f1f1f1",
    width: "100%",
    marginTop: 10,
  },
  tab: {
    width: "33.33%"
  },
  itemBody: {
    textAlign: "center",
    padding: '10px 5px',
    background: "#f1f1f1",
    borderRadius: 6
  },
  itemAvatar: {
    width: '100%'
  },
  avatarEmail: {
    marginTop: -20,
    color: '#202124',
    font: '400 14px/19px Roboto,RobotoDraft,Helvetica,Arial,sans-serif'
  },
  avatarName: {
    fontWeight: 300,
    color: '#202124',
    fontSize: 20,
    font: '400 14px/19px Roboto,RobotoDraft,Helvetica,Arial,sans-serif'
  },
  btnAvatar: {
    background: `#fff !important`,
    borderColor: `#000 !important`,
    textAlign: "left",
    color: "#000 !important",
    textTransform: "uppercase",
    display: "flex",
    margin: "0px!important",
    "& svg": {
      fill: '#000',
    },
    "& .ant-btn-block": {
      width: "97%"
    },
    "& .clf-icon-Btn": {
      left: "5px !important"
    }
  }

}))

function AccountPopup(props) {
  const {accountPopupAnchorEl, onClose} = props
  const classes = useStyles()
  const [user,] = useGlobal('user')
  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState(" ")


  const onClickLogout = async () => {
    setLoading(true)
    try {
      await store.logout()
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false)
    }

  };

  useEffect(() => {
    if (user?.avatar) {
      setAvatar(user.avatar)
    }
  }, [user])

  return (
    <div className="AccountPopup" data-html2canvas-ignore={true}>
      <Popover
        id={"AccountPopup"}
        open={Boolean(accountPopupAnchorEl)}
        anchorEl={accountPopupAnchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Card>
          <CardContent
            className={classes.cardContent}>
            <div className={classes.itemBody}>
              <div className={classes.itemAvatar}>
                {avatar ? <Avatar className={classes.avatar}
                                  src={avatar}/>
                  : <AccountCircle
                    className={classes.avatar}
                    color={'disabled'}
                  />
                }

                <p className={classes.avatarName}>{`${user?.last_name || ''} ${user?.first_name || ''}`}</p>
                <p className={classes.avatarEmail}>{`${user?.email || ''}`}</p>

              </div>
              <div className={classes.divRoot}>
                <CLFButtonSVG
                  borderRadius={'12px'}
                  name={'switchAccount'}
                  onClick={onClickLogout}
                  className={classes.btnAvatar}
                  disable={loading}
                  size={'default'}
                  iconComponent={<img src={'./icons/switchAccout.svg'} alt={''}/>}
                  block={true}
                  fontSize={"12px"}
                  fontWeight={500}
                />
              </div>
            </div>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div className={classes.divRoot}>
                  <Link className={`noUnderline ${classes.link}`} to="/">
                    <CLFButtonSVG
                      name={'setting'}
                      onClick={() => {
                        window.open(`https://${IS_PRODUCTION ? 'myaccount' : 'myaccount-stg'}.geniam.com/account`, '_blank')
                      }}
                      className={classes.antBtn}
                      size={'default'}
                      iconComponent={<img src={'./icons/accountSettings.svg'} alt={''}/>}
                      fontSize={"12px"}
                    />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.divRoot}>
                  <Link className={`noUnderline ${classes.link}`} to="/">
                    <CLFButtonSVG
                      name={'logOut'}
                      onClick={onClickLogout}
                      className={classes.antBtn}
                      size={'default'}
                      iconComponent={<img src={'./icons/SignOut.svg'} alt={''}/>}
                      fontSize={"12px"}
                    />
                  </Link>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Popover>
    </div>
  )
}

export default AccountPopup;
