import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {auth} from "../../firebaseConfig";
import firebase from "firebase"
import {setCookie} from "../../common/cookies";
import {ACCESS_TOKEN_KEY, API_URL, GENIAM_REFRESH_TOKEN} from "../../config/apiUrls";
import Store from "../../store";
import {setGlobal} from "reactn";
import axios from 'axios'
import {toast} from "react-toastify";

const ggProvider = new firebase.auth.GoogleAuthProvider();
const fbProvider = new firebase.auth.FacebookAuthProvider();
const appleProvider = new firebase.auth.OAuthProvider('apple.com');

export const socialLogin = (provider) => {
  auth.signInWithPopup(provider)
    .then(async (result) => {
      const loginData = result.user.toJSON();
      await axios.post(`${API_URL}/signin-social`, {
        email: loginData.email,
        uid: loginData.uid,
        displayName: loginData.displayName,
        photoURL: loginData.photoURL
      })
      setCookie(GENIAM_REFRESH_TOKEN, loginData.stsTokenManager.refreshToken)
      localStorage.setItem(ACCESS_TOKEN_KEY, loginData.stsTokenManager.accessToken)
      await Store.init()
      setGlobal({
        user: Store.user
      })
      toast.success("Login successful!");
    })
    .catch(async (error) => {
        console.log(
          'Error while signing',
          error.message
        );
        if (
          error?.credential?.providerId === 'facebook.com' &&
          error.code === 'auth/account-exists-with-different-credential') {
          try {
            const credential = error.credential;
            const email = error.email
            const {data} = await axios.post(`${API_URL}/account/fb-link-token`, {
              email,
              credential
            })
            const result = await auth.signInWithCustomToken(data.token)
            await result.user.linkWithCredential(credential)
            const loginData = result.user.toJSON();
            setCookie(GENIAM_REFRESH_TOKEN, loginData.stsTokenManager.refreshToken)
            localStorage.setItem(ACCESS_TOKEN_KEY, loginData.stsTokenManager.accessToken)
            await Store.init()
            setGlobal({
              user: Store.user
            })
          } catch (e) {
            toast.error(error.message);
          }
          return;
        }
        toast.error(error.message);
      }
    );

}

function SocialLogin(props) {
  const [loading,] = useState(false)
  const classes = useStyle()


  return (
    <div style={{marginTop: 10}}>
      {/*<Divider className={classes.line}/>*/}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <button onClick={() => socialLogin(ggProvider)}
                  className={`${classes.button} ${classes.btnGoogle}`}
                  disabled={loading}
          >
            <img alt={'google'} src={'/social/google.svg'}/>
            <span>
                Google でログイン
              </span>
          </button>
        </Grid>
        <Grid item xs={12}>
          <button onClick={() => socialLogin(fbProvider)}
                  className={`${classes.button} ${classes.btnFb}`}
                  disabled={loading}
          >
            <img alt={'facebook'} src={'/social/facebook.svg'}/>
            <span style={{color: "#fff"}}>
                facebook でログイン
              </span>

          </button>
        </Grid>
        <Grid item xs={12}>
          <button onClick={() => socialLogin(appleProvider)}
                  className={`${classes.button} ${classes.btnApple}`}
                  disabled={loading}
          >
            <img alt={'apple'} src={'/social/apple.svg'}/>
            <span style={{color: "#fff"}}>
                apple でログイン
              </span>

          </button>
        </Grid>
      </Grid>
      <div className={classes.line}>
        <span>または</span>
      </div>


    </div>
  );
}

export default SocialLogin;
const useStyle = makeStyles(theme => ({
  button: {
    width: "100%",
    textAlign: "left",
    padding: '0 20px',
    borderRadius: 25,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    height: 50,
    position: "relative",
    "& img": {
      position: "absolute",

      left: 15,
      display: "inline-block",
      height: 30,
      width: 30,
      textAlign: "center",
      verticalAlign: "middle"
    },
    "& span": {
      fontWeight: "bold",
      lineHeight: "30px",
      verticalAlign: "middle",
      display: "inline-block",
      fontSize: 18,
      textTransform: "uppercase"
    },
    "&:hover": {
      opacity: 0.8
    }
  },

  textIcon: {
    color: "#ffffff",
    fontWeight: "bold",
    lineHeight: "30px",
    verticalAlign: "middle",
    display: "inline-block",
    whiteSpace: "nowrap"
  },
  btnFb: {
    backgroundColor: "#1877F2",
  },
  btnApple: {
    backgroundColor: "#000",
  },
  btnGoogle: {
    backgroundColor: "#fff",
    border: "1px solid #9D9D9D",
  },
  line: {
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    fontSize: '16px',
    margin: '20px 0',
    zIndex: 2,
    "& span": {
      backgroundColor: "#fff",
      display: "inline-block",
      padding: "0 15px"
    },
    "&:before": {
      position: 'absolute',
      top: '50%',
      height: '1px',
      background: '#b7b7b7',
      content: "''",
      // width: '100%',
      left: '10px',
      right: "10px",
      marginTop: '-1px',
      zIndex: -1
    },
  }
}))
