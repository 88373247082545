import {Button, Typography,} from '@material-ui/core';
import * as React from 'react';
// @ts-ignore
import {RouteComponentProps, withRouter} from 'react-router-dom';
import CartSummary2 from '../component/CartSummary2';
import Footer from '../component/Footer';
import InformationBar from '../component/InformationBar';
import PaymentMethodList from '../component/PaymentMethodList';
import store from '../store';
import IPaymentMethod from '../store/IPaymentMethod';
import Link from "@material-ui/core/Link";
import {Elements} from "react-stripe-elements";
import PaymentRequestForm from "../component/PaymentRequestForm";
import FormattedMessage from "../context/FormatedMessage";
import ButtonAppBar from "../component/GeniamBar/MenuAppBar";

interface IState {
    options?: string;
    methods?: IPaymentMethod[];
    selected?: string;
    usePoint?: boolean;
    points?: number;
}

class PaymentView extends React.Component<RouteComponentProps<{}>, IState> {
    public state = {
        options: '',
        methods: store.paymentMethods,
        selected: '',
        usePoint: store.isUseGeniamPoint,
        points: store.useGeniamPointAmount,
    }

    public componentDidMount() {
        if (store.cart.length === 0 || store.user == null) {
            this.props.history.push('/');
        }

        store.refreshCart().then(() => {
            if (store.selectedPaymentId === 'transferBanking') {
                this.setState({
                    selected: "transferBanking"
                });
                store.selectedPaymentId = "transferBanking";
                store.store();
            }
        });
    }

    public render() {
        return (
            <div>
                <ButtonAppBar/>
                <div className={"div-content"}>
                    <InformationBar step={2}/>
                    <div className="flex-base">

                        <div className="flex-main">
                            <div className="panel">
                                <div className="centering"><Typography variant="h4"><FormattedMessage
                                    id='cart.payment.method'/></Typography></div>
                                <br/>
                                <PaymentMethodList
                                    methods={this.state.methods}
                                    onSelect={this.handleSelectMethods}
                                    onUpdatePaymentMethods={this.handleUpdate}
                                />
                                {
                                    store.selectedPaymentId ? (
                                        <div className="centering m10">
                                            <Button variant="contained" color="primary" onClick={this.handleClickNext}
                                                    size={"large"}
                                                    style={{background: "#FB8800", fontSize: "18px", minWidth: "35%", marginTop: "10px"}}
                                                    disabled={this.state.selected === ''}><FormattedMessage
                                                id='cart.payment.button'/></Button>
                                        </div>
                                    ) : ('')
                                }
                            </div>
                            <div style={{textAlign: "center"}} className="panel">
                                <Typography><FormattedMessage id='cart.payment.titleBottom'/></Typography>
                                <Link style={{cursor: "pointer"}} onClick={() => {
                                    window.location.href = 'https://www.visa.co.jp/pay-with-visa/find-a-card/prepaid-cards.html?redirect_url=' + window.location.href
                                }}>
                                    <Typography>https://www.visa.co.jp/pay-with-visa/find-a-card/prepaid-cards.html</Typography>
                                </Link>
                            </div>
                            <div className="panel" style={{background: "transparent"}}>
                                <div style={{marginBottom: "10px"}}>
                                    <img src="/static/images/cards/stripe.png" height={43} alt=''/>
                                </div>
                                <p>クレジットカード情報を安全に扱うために、世界最高水準のセキュリティレベルの決済サービスStripeを利用しています。</p>
                                <p>クレジットカード情報は、株式会社アンダムが運営するジニアムには保存されませんが、カート上ではStripeと連携しクレジットカードをご利用できます。</p>
                            </div>
                        </div>
                        <div className="flex-sub">
                            <div className="panel">
                                <CartSummary2/>
                            </div>
                            <div className="centering m10">
                                <Elements>
                                    <PaymentRequestForm/>
                                </Elements>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }

    private handleClickNext = () => {
        this.props.history.push(store.next());
    }

    private handleSelectMethods = (id: string) => {
        this.setState({
            selected: id
        });

        store.selectedPaymentId = id;
        store.store();
    }

    private handleUpdate = () => {
        (async () => {
            await store.login();
            await store.updatePaymentMethods();
            this.setState({
                methods: store.paymentMethods
            });
        })();
    }
}

export default withRouter(PaymentView);
