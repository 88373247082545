import React from 'react';
import Typography from '@material-ui/core/Typography';

function AppCodeAddedInformation(props) {
  const {couponCodeList, servicesList} = props
  return (
    <div>
      <Typography>Coupon code has ben added</Typography>
      {
        couponCodeList && couponCodeList.length !== 0 && couponCodeList.map(apcs => {
          let appList = apcs.typesUsed.apps
          return (
            appList && appList.length !== 0 && appList.map((apc, index) => {
              let service = servicesList.find(s => s.service_id === apc.service_id)
              if (service) {
                if (apc.isUnlimited === false)
                  return (
                    <Typography key={index} style={{color: "red", fontSize: "small"}}>Time used
                      by {service.service_name} up
                      to {apc.timeUsedApp} by Coupon code: {apcs.code_id}</Typography>
                  )
                return <Typography key={index} style={{color: "red", fontSize: "small"}}>Time used
                  by {service.service_name} used to
                  unlimited by Coupon code: {apcs.code_id}</Typography>
              }
              return null
            })
          )
        })
      }
    </div>
  );
}

export default AppCodeAddedInformation;
