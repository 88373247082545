import axios from 'axios'
// import {setGlobal} from 'reactn'
import Cookies from 'js-cookie'
import {ACCESS_TOKEN_KEY, API_GENIAM, GENIAM_REFRESH_TOKEN} from "../config/apiUrls";
import {removeCookie, setCookie} from "../common/cookies";


// export const getUserData = async () => {
//   const token = localStorage.getItem('accessToken')
//   if (!token) {
//     const res = await refreshToken()
//     if (!res) return null
//   }
//
//   try {
//     const {data} = await axios.get(GET_USER_INFO)
//     await setGlobal({user: data})
//     localStorage.setItem('userInfo', JSON.stringify(data))
//     return true
//   } catch (e) {
//     console.log(e)
//     return null
//   }
//
// }


export const refreshToken = async () => {
  const refreshToken = Cookies.get(GENIAM_REFRESH_TOKEN);

  if (!refreshToken) {
    // remove un know + '?cookies'
    // window.location.href = URL_MYACCOUNT + '/login?redirect_url=' + encodeURIComponent(window.location.href)
    return null
  }
  // refreshToken
  try {
    const res = await axios.post(API_GENIAM + '/refresh', {refreshToken});
    const data = res.data;
    localStorage.setItem('accessToken', data.accessToken)
    if (data.refreshToken)
      setCookie(GENIAM_REFRESH_TOKEN, data.refreshToken);
    return data;
  } catch (e) {
    console.log(e)
    removeCookie(GENIAM_REFRESH_TOKEN)
    removeCookie(ACCESS_TOKEN_KEY)
    return null;
  }
}

