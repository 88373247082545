import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useGlobal} from 'reactn';
import AccountPopup from './AccountPopup';
import axios from 'axios';
import {ACCESS_TOKEN_KEY, GENIAM_REFRESH_TOKEN, IS_PRODUCTION} from '../../config/constant';
import Cookies from 'js-cookie';
import {NavigationGlobal} from '@Andom-Geniam/git-globalnavi';
import {db} from '../../firebaseConfig';
import {API_GENIAM} from "../../config/apiUrls";
import LoginRegister from "./LoginRegister";
import {AppBar, Toolbar, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    color: 'white',
    "& .MuiToolbar-root": {
      paddingRight: 0
    }
  },
  AppBar: {
    backgroundColor: '#FFFFFF'
  },
  logoImg: {
    width: 50,
    height: 50
  },
  title: {},
  grow: {
    flexGrow: 1,
    color: "#0576fe",
    fontWeight: "bold"
  },
  topRight: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      // width: "100%",
      justifyContent: "flex-end"

    },
    [theme.breakpoints.down('xs')]: {
      display: "block",
      background: "#f1f1f1",
    },
  }
}));

function ButtonAppBar() {
  const classes = useStyles();
  const [user] = useGlobal('user');
  const [, setLoading] = useState(false)

  const onClickLogout = async () => {
    setLoading(true)
    try {
      await axios.post(API_GENIAM + '/logout');
      const isProd = process.env.REACT_APP_ENV === 'production';
      const isStg = process.env.REACT_APP_ENV === 'stg';
      const domain = (isProd || isStg) ? '.geniam.com' : null;
      // localStorage.clear();
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      Cookies.remove(GENIAM_REFRESH_TOKEN, {domain});
      window.location.reload();
      return null;
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false)
    }

  };

  const onClickAccount = () => {
    window.open(`https://${IS_PRODUCTION ? 'myaccount' : 'myaccount-stg'}.geniam.com/account`, '_blank')
  }

  const myAccountLink = `https://myaccount${process.env.REACT_APP_ENV === 'production' ? '' : '-stg'}.geniam.com`

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.AppBar}>
        <Toolbar>
          <Typography color="inherit" variant="h5" className={classes.grow}
                      style={{cursor: "pointer"}}
                      onClick={() => window.location.href = myAccountLink}>
            <img className={classes.logoImg} src={'static/images/logo cart.svg'} alt=''/><span
            className={classes.title}>Cart</span>
          </Typography>
          {
            user?.user_id ?
              <div className={classes.topRight}>
                <div>
                  <NavigationGlobal
                    user={user}
                    db={db}
                    service={"cart2"}
                    onClickLogOut={onClickLogout}
                    switchAccount={onClickLogout}
                    onClickAccount={onClickAccount}
                    intl={{}}
                    notification={false}
                    hiddenApp={true}
                  />
                </div>
              </div>
              :
              <div className={classes.divRegister}>
                <LoginRegister/>
              </div>
          }
        </Toolbar>
      </AppBar>
      <AccountPopup/>
    </div>
  );
}

export default ButtonAppBar;
