import React, {useState} from 'react';
import SocialLogin from "./SocialLogin";
import {Button, makeStyles, TextField, Typography} from '@material-ui/core'
import validateEmail from "../../common/validateEmail";
import registerEmailAsync from "../../common/registerEmailAsync";

function Register(props) {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [confirmed, setConfirmed] = useState(false)
  const onChange = (e) => {
    setEmail(e.target.value)
    setErrors(null)

  }
  const onEmailInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!email)
        return;
      if (!validateEmail(email)) {
        setErrors({email: "invalid email"})
        return;
      }
      handleRegister()
    }
  }

  const onRegister = () => {
    if (!email) {
      setErrors({email: "email required"})
      return;
    }
    if (!validateEmail(email)) {
      setErrors({email: "invalid email"})
      return;
    }
    handleRegister()
  }
  const handleRegister = async () => {
    try {
      setLoading(true)
      setConfirmed(false)
      const {status} = await registerEmailAsync(email)
      if (status === 210) {
        // email exists
        setErrors({
          email: 'User is already exists'
        })
        setLoading(false)
        return;
      }
      setConfirmed(true)
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.message) {
        if (err.response.data.message === "User is already confirmed.") {
          setErrors({
              email: err.response.data.message
            }
          )
        } else if (err.response.data.message === "Attempt limit exceeded, please try after some time.") {
          setErrors({
            email: "しばらく経ってから、もう一度メールアドレスを入力してください。\n" +
              "※短時間に複数回同じメールアドレスが入力されると、セキュリティ上エラーが発生します。"
          })
        }
      }
    }
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>ソーシャルログインで新規登録して購入</Typography>
      <SocialLogin/>
      <div>メールアドレスで新規登録して購入</div>
      <TextField
        id="email"
        className={"w100 mb-20"}
        value={email}
        label={"メールアドレス"}
        onChange={onChange}
        onKeyDown={onEmailInputKeyDown}
        disabled={loading}
        margin="normal"
        variant="outlined"
        helperText={errors && errors.email ? errors.email : null}
        error={!!(errors && errors.email)}
      />
      {confirmed &&
        <div className={"mb-20"}>
          ご登録URLをメールへ送信いたしました。<br/>
          メール内のURLをクリックして新規登録へお進みください。
        </div>
      }
      <Button
        className={`btnPrimary ${classes.button}`}
        fullWidth={true}
        onClick={onRegister}
        disabled={loading}
      >
        新規登録して購入
      </Button>

    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    background: "#fff",
    padding: "25px 50px",
    borderRadius: "8px"

  },
  title: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: 16
  },
  subTittle: {
    fontWeight: 700,
    fontSize: 16,
    textAlign: "left"
  },
  chipContainer: {
    marginRight: 3
  },
  chip: {
    color: "white", height: 24
  },
  activeChip: {
    backgroundColor: "#3A3A3A",
    color: "white",
    height: 24
  },
  button: {
    fontSize: 20
  }
}))
export default Register;
