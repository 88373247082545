import {Button, Typography} from '@material-ui/core';
import * as React from 'react';
// @ts-ignore
import {RouteComponentProps, withRouter} from 'react-router-dom';
import DeliveryList from '../component/DeliveryList';
import Footer from '../component/Footer';
import InformationBar from '../component/InformationBar';
import store from '../store';
import IDeliveryItem from '../store/IDeliveryItem';
import ButtonAppBar from "../component/GeniamBar/MenuAppBar";
import FormattedMessage from "../context/FormatedMessage";

interface IState {
  deliveries?: IDeliveryItem[];
  selectedDelivery?: number;
}

class DeliveryView extends React.Component<RouteComponentProps<{}>, IState> {
  public state = {
    deliveries: store.deliveries,
    selectedDelivery: 0
  }

  public componentDidMount() {
    if (store.cart.length === 0 || store.user == null) {
      this.props.history.push('/');
    }
  }

  public render() {
    return (
      <div>
        <ButtonAppBar/>
        <div className={"div-content"}>
          <InformationBar step={1}/>
          <div className="flex-base">
            <div className={"flex-sub-sub"}/>
            <div className="flex-main">
              <div className="panel">
                <div className="centering"><Typography variant="h4">お届け先の選択</Typography></div>
                <DeliveryList deliveries={this.state.deliveries} onUpdateDeliveries={this.handleUpdateDelivery}
                              onSelect={this.handleSelectDelivery}/>
                {this.state.deliveries.length > 0 ? (
                  <div className="centering m10">
                    <Button variant="contained" color="primary" onClick={this.handleClickNext}
                            size={"large"}
                            style={{background: "#8CC358", color: "#fff", fontSize: "18px", minWidth: "35%", marginTop: "10px", boxShadow: "none"}}
                            disabled={this.state.selectedDelivery === 0}><FormattedMessage id='cart.payment.button'/></Button>
                  </div>
                ) : ('')}
              </div>
            </div>
            <div className={"flex-sub-sub"}/>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }

  private handleUpdateDelivery = () => {
    (async () => {
      await store.login();
      await store.updateDeliveries();
      this.setState({
        deliveries: store.deliveries
      });
    })();
  }

  private handleSelectDelivery = (id: string) => {
    this.setState({
      selectedDelivery: Number.parseInt(id, 10)
    });
    store.selectedDeliveryId = Number.parseInt(id, 10);
    store.store();
  }

  private handleClickNext = () => {
    this.props.history.push(store.next());
  }
}

export default withRouter(DeliveryView);
