import {findIndex} from "lodash/array";

const getPayMethodProductGeneral = (listProduct, uid) => {
    let arr = [];
    if (listProduct.every(product => product?.is_payone)) {
        arr.push({name: "一括", selected: true, label: "一括"})
    }
    if (listProduct.every(product => product?.is_installment && product?.divided_times)) {
        let idxProduct = findIndex(listProduct, {'product_id': uid});
        let idxPackage = findIndex(listProduct, {'package_id': uid});
        if (idxProduct !== -1) {
            arr.push({
                name: "分割",
                selected: true,
                label: `クレジットカード分割${listProduct[idxProduct].divided_times}回`
            })
        } else if (idxPackage !== -1) {
            arr.push({
                name: "分割",
                selected: true,
                label: `クレジットカード分割${listProduct[idxPackage].divided_times}回`
            })
        } else {
            arr.push({name: "分割", selected: true, label: '分割'})
        }
    }
    if (listProduct.every(product => product?.is_paymonth && product?.amount_month)) {
        arr.push({name: "月払い", selected: true, label: '月払い'})
    }
    if (listProduct.every(product => product?.is_payyear && product?.amount_year)) {
        arr.push({name: "年払い", selected: true, label: '年払い'})
    }
    if (listProduct.every(product => product?.is_transfer_banking)) {
        arr.push({name: "銀行振込", selected: true, label: "銀行振り込み（一括払い）"})
    }
    return arr;
}
export default getPayMethodProductGeneral;
