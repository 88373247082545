import {API_GENIAM} from "../config/apiUrls";
import {MYACCOUNT_URL} from "./constants";
import axios from "axios";

const registerEmailAsync = async (email) => {
  let postData = {
    email,
    service_id: 0,
    course_id: 0,
    redirect_url: MYACCOUNT_URL + '/register/name-password-input?email=' + email,
    params: {
      service_redirect_url: window.location.href
    }
  }
  return await axios.post(`${API_GENIAM}/signup`, postData)
}

export default registerEmailAsync
