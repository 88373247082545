import {createStyles, Theme, Typography, withStyles, WithStyles} from '@material-ui/core';
import * as React from 'react';
import Grid from "@material-ui/core/Grid";
import FormattedMessage from "../context/FormatedMessage";

const styles = (theme: Theme) => createStyles({
  link: {
    fontSize: 'small',
    color: 'white'
  }
});

interface IProps extends WithStyles<typeof styles> {
}

class Footer extends React.Component<IProps> {
  public render() {
    const {classes} = this.props;

    return (
      <footer>
        <div className="centering">
          <div>
            <a href="https://geniam.com/" className="m10" target="_blank" rel="noopener noreferrer"><span
              className={classes.link}><FormattedMessage id='cart.footer.inventorCompany'/></span></a>
            <a href="https://myaccount.geniam.com/account/terms" className="m10" target="_blank"
               rel="noopener noreferrer"><span className={classes.link}><FormattedMessage
              id='cart.footer.termOfService'/></span></a>
            <a href="https://myaccount.geniam.com/account/privacy" className="m10" target="_blank"
               rel="noopener noreferrer"><span className={classes.link}><FormattedMessage
              id='cart.footer.privacyPolicy'/></span></a>
            <a href="https://myaccount.geniam.com/account/patent-commerce" className="m10" target="_blank"
               rel="noopener noreferrer"><span className={classes.link}><FormattedMessage
              id='cart.footer.notationBase'/></span></a>
          </div>
          <Grid container direction={"column"} alignContent={"center"} alignItems={"center"}>
            <img src="static/images/favicon.ico" alt="favicon" width="30" height="30"/>
            <Typography color="inherit" variant="h5">Geniam</Typography>
            <Typography color="inherit" variant="caption">Copyright (C) 2018 Andom.inc All Rights
              Reserved.</Typography>
          </Grid>
        </div>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
