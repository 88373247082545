import {isArray, isEmpty} from 'lodash';
import {useContext} from 'react'
import {LanguageContext} from "../../context/LanguageContext";

/**
 * @param {string|array} id
 * @param values
 */

export default (id, values) => {

  const intl = useContext(LanguageContext);
  if (isEmpty(intl))
    return id
  return isArray(id) ?
    id.map(i => intl[i] || i)
    : (intl[id] || id)
}
