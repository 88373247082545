import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Radio,
  Typography,
} from '@material-ui/core';
import {ControlPoint, Delete, Edit} from '@material-ui/icons';
import * as React from 'react';
import API from '../common/API';
import store from '../store';
import IDeliveryItem from '../store/IDeliveryItem';
import DeliveryDialog from './dialog/DeliveryDialog';

interface IProps {
  deliveries: IDeliveryItem[];
  onSelect: (id: string) => void;
  onUpdateDeliveries: () => void;
}

interface IState {
  dialogOpen: boolean;
  alertOpen: boolean;
  selectedDelivery: string;
  recordId: number;
  deleteDelivery: number;

}

class DeliveryList extends React.Component<IProps, IState> {
  public state = {
    dialogOpen: false,
    alertOpen: false,
    selectedDelivery: '',
    recordId: 0,
    deleteDelivery: 0
  }

  public componentDidMount() {
    let dialogOpen = false;
    if(this.props.deliveries.length === 0){
      dialogOpen = true;
    }

    const recordId = store.getSelectedDelivery() !== null ? store.selectedDeliveryId.toString() : (this.props.deliveries[0] ? this.props.deliveries[0].record_id.toString() : '');
    this.setState({
      selectedDelivery: recordId,
      dialogOpen
    });

    this.props.onSelect(recordId);
  }

  public render() {
    const { deliveries } = this.props;

    // @ts-ignore
    return (
      <div>
        {deliveries.length === 0 ? (
          <div className="centering">
            <Button style={{background: "#8CC358", color: "#fff", padding: "8px 25px 8px 25px", margin: 20}} onClick={this.handleOpenNew} startIcon={<ControlPoint />} >
              新たなお届け先の登録
            </Button>
          </div>
        ) : (
          <div className="left">
            <Button style={{background: "#fff", color: "#8CC358", fontSize: "16px", padding: "8px 25px 8px 25px", border: "1px solid #8CC358"}} onClick={this.handleOpenNew} startIcon={<ControlPoint fontSize={"large"} />} >
              お届け先の登録
            </Button>
          </div>
        )}
        <br/>
        <Grid container={true} spacing={4}>
          {deliveries.map((d, i) => (
            <Grid item={true} md={4} sm={6} xs={12} key={d.record_id}>
              <Card className={"delivery__card"}>
                <CardContent style={{paddingBottom: 0}}>
                  <div className={"delivery__card-header"}><Typography
                      style={{fontSize: "18px", marginBottom: 0}}>お届け先{(i + 1)}</Typography>
                    <Radio
                      checked={this.state.selectedDelivery === d.record_id.toString()}
                      onChange={this.handleChangeSelect}
                      value={d.record_id.toString()}
                      name="delivery"
                      color="primary"
                      id={"delivery_" + d.record_id}
                    />
                  </div>
                  {d.address_name}<br/>
                  〒{d.address_zip}<br/>
                  {d.address_state} {d.address_city} {d.address_street}<br/>
                  {d.address_building}<br/>
                  ℡：{d.tel}
                </CardContent>
                <CardActions className={"delivery__card-action"}>
                  <IconButton onClick={this.handleOpenEdit(d.record_id)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={this.handleOpenAlert(d.record_id)} style={{margin: 0}}>
                    <Delete />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <DeliveryDialog
          open={this.state.dialogOpen}
          onClose={this.handleCloseDialog}
          recordId={this.state.recordId}
        />
        <Dialog
          open={this.state.alertOpen}
          onClose={this.handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">削除しますか？</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              選択したお届け先を削除しますか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="default" onClick={this.handleCloseAlert}>キャンセル</Button>
            <Button variant="contained" color="secondary" onClick={this.handleDelete}>削除</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  private handleOpenNew = () => {
    this.setState({
      dialogOpen: true,
      recordId: 0,
    });
  }

  private handleOpenEdit = (recordId: number) => () => {
    this.setState({
      dialogOpen: true,
      recordId: recordId
    })
  }

  private handleOpenAlert = (recordId: number) => () => {
    this.setState({
      alertOpen: true,
      deleteDelivery: recordId
    });
  }

  private handleCloseAlert = () => {
    this.setState({
      alertOpen: false
    });
  }

  private handleDelete = () => {
    // TODO : confirm dialog
    (async () => {
      this.setState({
        alertOpen: false
      });
      await API.deleteDelivery(store.accessToken as string, this.state.deleteDelivery);
      this.props.onUpdateDeliveries();
    })();
  }

  private handleCloseDialog = (refresh: boolean) => () => {
    this.setState({
      dialogOpen: false,
    });

    if (refresh) {
      this.props.onUpdateDeliveries();
    }
  }

  private handleChangeSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      selectedDelivery: ev.target.value
    });

    this.props.onSelect(ev.target.value);
  }
}

export default DeliveryList;
