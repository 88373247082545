import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";
import axios from 'axios'
import {useGlobal} from 'reactn'
import _ from 'lodash'
import currencyFormat from "../common/currencyFormat";
import store from 'store'

const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
});

/**
 * @return {null}
 */
function SeichokuOptions({ classes, product }) {

  // console.log(product)
  // console.log({ cart })
  // const productIds = cart.map(c => c.product_id)

  const [opts, setOpts] = useGlobal('seichokuOptions')
  // noinspection JSUnusedLocalSymbols
  // const [seichokuOptions, setSeichokuOptions] = useGlobal('seichokuOptions')

  useEffect(() => {
    if (!_.isEmpty(opts[product.product_id])) return
    axios.get(
      `/v1/cart/getSeichokuOptions`,
      { params: { product_id: product.product_id } })
      .then((res) => {
        let { data } = res
        if (data) {
          data.map(d => {
            if (d.code === 0) d.checked = true
            return d;
          })
          setOpts({ ...opts, [product.product_id]: data })
        }
      }).catch(console.log)
    // eslint-disable-next-line
  }, [])

  const handleToggle = opt => {
    return () => {
      // console.log(option)
      const productOpts = opts[product.product_id]
      const idx = productOpts.indexOf(opt)
      if (idx === -1) return
      const newOptionList = productOpts.map(o => {
        o.checked = false
        return o
      })
      const filtered = newOptionList.find(o => o.code === opt.code)
      if (filtered) {
        filtered.checked = true
        newOptionList[idx] = filtered
        const newOpts = { ...opts, ...{ [product.product_id]: newOptionList } }
        // console.log({ newOpts })
        setOpts(newOpts)
        store.set('seichokuOptions', newOpts)
        // setSeichokuOptions(filtered)
      }
      // store.calcFee()
    };
  };

  if (!opts[product.product_id]) return null
  return (
    <List className={classes.root}>
      {opts[product.product_id].map(opt => (
        <ListItem key={opt.ja_name} role={undefined} dense button onClick={handleToggle(opt)}>
          <Checkbox
            checked={!!opt.checked}
            tabIndex={-1}
            disableRipple
            onChange={(e) => {
              e.preventDefault()
            }}
          />
          <ListItemText primary={`${opt.ja_name} +${currencyFormat.format(opt.amount)}/冊`}
                        secondary={
                          <React.Fragment>
                            <Typography component="span" className={classes.inline} color="primary">
                              {opt.description}
                            </Typography>
                          </React.Fragment>
                        }
          />
        </ListItem>
      ))}
    </List>
  );
}

SeichokuOptions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SeichokuOptions);
