import * as React from 'react';
import {useEffect, useMemo} from 'react';
import {createTheme, MuiThemeProvider} from '@material-ui/core';
import {red} from '@material-ui/core/colors';
import {createBrowserHistory} from 'history';
import * as ReactGA from 'react-ga';
import {Route, Router, Switch} from 'react-router-dom';
import {StripeProvider} from 'react-stripe-elements';
import {STRIPE_PUBLISH_KEY} from './common/Stripe';
import CartEditView from './view/CartEditView';
import CartView from './view/CartView';
import DeliveryView from './view/DeliveryView';
import PaymentView from './view/PaymentView';
import ThanksView from './view/ThanksView';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {db} from './firebaseConfig';
import _ from 'lodash';
import {getGlobal, setGlobal} from 'reactn';
import qs from 'query-string';
import {ACCESS_TOKEN_KEY, GENIAM_REFRESH_TOKEN} from './config/apiUrls';
import AuthenticationCode from "./component/Auth/AuthenticationCode";
import store from 'store';
import ForgotPassword from "./component/Auth/ForgotPassword";
import {isProductionEnv} from "./config/env";
import 'antd/dist/antd.css';
import "@Andom-Geniam/git-globalnavi/dist/index.css";
import ConfirmationView from "./view/ConfirmationView";

const theme = createTheme({
  palette: {
    primary: {
      light: '#a2cf6e',
      main: '#8bc34a',
      dark: '#618833',
      contrastText: '#ffffff',
    },
    secondary: red,
  },
});

ReactGA.initialize('UA-123685291-3');

const history = createBrowserHistory();
history.listen((location, action) => {
  window.scrollTo(0, 0);
  ReactGA.pageview(location.pathname);
});

export const PACKAGE_COLL = isProductionEnv ? 'packages' : 'packages-stg'

function App2() {
  const cartData = useMemo(() => {
    return store.get('cart.cart') || []
  }, []);

  useEffect(() => {
    // init global cart data for global addCallBack
    if (!cartData.length)
      return;
    setGlobal({cart: cartData})
  }, [cartData])

  useEffect(() => {
    const query = qs.parse(window.location.search);
    const path = window.location.pathname;

    if (query?.token || query?.refreshToken) {
      store.set(ACCESS_TOKEN_KEY, query?.token);
      store.set(GENIAM_REFRESH_TOKEN, query?.refreshToken);

      delete query.token;
      delete query.refreshToken;
      history.push(`${path}?${qs.stringify(query)}`)
    }

    let cartPackages = store.get('cart.packages');
    const unsubscribe = db.collection(PACKAGE_COLL).onSnapshot(function (snapshot) {
      const {cart} = getGlobal()
      snapshot.docChanges().forEach(function (change) {
        if (change.type === "modified") {
          let idxCart = _.findIndex(cart, {'package_id': change.doc.id})
          if (idxCart !== -1) {
            cart[idxCart].amount = change.doc.data().price;
            cart[idxCart].appList = change.doc.data().appList;
            cart[idxCart].addedServiceList = change.doc.data().addedServiceList;
            cart[idxCart].payMethod = change.doc.data().payMethod;
          }
          // change for local all packages
          let changeIdx = _.findIndex(cartPackages, {'id': change.doc.id});
          if (changeIdx !== -1) {
            cartPackages[changeIdx] = change.doc.data();
          }
        }

        if (change.type === 'removed') {
          let idxCart = _.findIndex(cart, {'package_id': change.doc.id});
          if (idxCart !== -1) {
            _.remove(cart, cart[idxCart]);
            // change for local all packages
            _.remove(cartPackages, c => c.id === change.doc.id);
          }
        }
      });
      setGlobal({cart: [...cart]});
      store.set('cart.cart', [...cart]);
      store.set('cart.packages', _.uniqBy(cartPackages, 'id'));

    }, function (error) {
      console.log(error.toString());
    });

    if (process.env.REACT_APP_ENV !== 'production' && process.env.NODE_ENV === 'development') {
      document.domain = window.location.hostname;
    }

    return () => {
      try {
        if (unsubscribe) unsubscribe();
      } catch (e) {
        console.log(e.toString());
      }
    };
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <StripeProvider apiKey={STRIPE_PUBLISH_KEY}>
        <Router history={history}>
          <Switch>
            <Route exact={true} path='/' component={CartView}/>
            <Route exact={true} path='/confirmation' component={ConfirmationView}/>
            <Route exact={true} path='/delivery' component={DeliveryView}/>
            <Route exact={true} path='/edit' component={CartEditView}/>
            <Route exact={true} path='/payment' component={PaymentView}/>
            <Route exact={true} path='/thanks' component={ThanksView}/>
            <Route exact={true} path='/socialAuth' component={AuthenticationCode}/>
            <Route exact={true} path={'/forgot-password'} component={ForgotPassword}/>
          </Switch>
          {/*// @ts-ignore */}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover/>
        </Router>
      </StripeProvider>
    </MuiThemeProvider>
  );

}

export default App2;
