import React, {useEffect, useState} from 'react';
import {getGlobal, setGlobal, useGlobal} from 'reactn';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import {getCouponsInfo} from '../actions/coupon';
import axios from 'axios';
import {API_GENIAM} from '../config/apiUrls';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {Delete} from '@material-ui/icons';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import useIntlId from './hooks/useIntlId';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: '375px',
    overflowY: 'scroll',
  },
  buttonHandle: {
    margin: theme.spacing(1),
    fontSize: 'small',
  },
  label: {
    textAlign: "left",
    display: "block",
    width: "100%"
  },
  input: {
    background: "#F5F7FB",
    border: 0,
    borderRadius: "5px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f5f5f5"
    }
  },
}));

function CartCoupons(props) {
  const classes = useStyles();
  const [dense] = useState(false);
  const [codeList, setCodeList] = useState([]);
  const [couponData, setCouponData] = useState('');
  const [userID, setUserID] = useState('');
  const [openDialogDeleteCode, setOpenDialogDeleteCode] = useState(false);
  const [deleteTargetCode, setDeleteTargetCode] = useState('');
  const [couponsCodeList] = useGlobal('couponsCodeList');
  const couponsCodes = JSON.parse(localStorage.getItem('couponsCodes'));
  const [loading, setLoading] = useState(false);
  const [buttonName, labelAdd, titleDelete, cancelButton, deleteButton] = useIntlId(
    [
      'cart.coupon.button',
      'cart.coupon.labelAdd',
      'home.cart.titleDelete',
      'home.cancel',
      'home.delete']);

  useEffect(() => {
    reLoadCouponData();
    // eslint-disable-next-line
  }, []);

  const reLoadCouponData = async (event) => {
    if (couponsCodes && couponsCodes.length !== 0) {
      couponsCodes.map(async c => {
        let couponCode = await onclickAddCoupon(event, c);
        return couponCode;
      });
    }
  };

  const getUserID = async () => {
    try {
      const {data} = await axios.get(API_GENIAM + '/getuserdata');
      if (_.isEmpty(data))
        return null;
      await setUserID(data.user_id);
      return data.user_id;
    } catch (e) {
      console.log(e);
    }
  };
  const handleChange = async (event) => {
    await setCouponData(event.target.value.toString());
  };

  const checkCouponInfo = async () => {
    try {
      let userID = await getUserID();
      let newCodeCheck = [];
      await newCodeCheck.push(couponData);
      let couponInfo = await getCouponsInfo(newCodeCheck, userID);
      return couponInfo;
    } catch (e) {
      return console.log(e.toString());
    }
  };

  const onclickAddCoupon = async (event, codeID) => {
    try {
      setLoading(true);

      if (codeID !== undefined)
        await codeList.push(codeID);
      else {
        let codeChecked = await checkCouponInfo();
        if (_.isEmpty(codeChecked)) {
          setLoading(false);
          setCouponData('');
          return null;
        }
        await codeList.push(couponData);
      }

      let newCodeList = _.uniqBy(codeList);
      await setCodeList(newCodeList);
      let couponsInfo = await getCouponsInfo(newCodeList, userID);

      await setGlobal({
        couponCodeList: [...couponsInfo],
        couponsCodeList: _.uniqBy([...newCodeList]),
        // appCodeAdded: [{...couponsInfo[0].typesUsed.apps}]
      });
      await localStorage.setItem('couponsCodes',
        JSON.stringify(_.uniqBy([...newCodeList])));
      setCouponData('');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setCouponData('');
      return console.log(e.toString());
    }
  };

  const handleDeleteCode = (code) => {
    setDeleteTargetCode(code);
    setOpenDialogDeleteCode(true);
  };

  const onCloseDialogDeleteCode = () => {
    setDeleteTargetCode('');
    setOpenDialogDeleteCode(false);
  };

  const handleDoDelete = async () => {
    let {couponCodeList} = await getGlobal();
    //remove code in list
    let newCodeList = await codeList.filter(c => c !== deleteTargetCode);
    // remove info code delete
    let newCodeListInfo = await couponCodeList.filter(
      c => c.code_id !== deleteTargetCode);
    await setCodeList([...newCodeList]);
    await setGlobal(
      {
        couponsCodeList: [...newCodeList],
        couponCodeList: [...newCodeListInfo],
      });
    await localStorage.setItem('couponsCodes',
      JSON.stringify([...newCodeList]));
    setOpenDialogDeleteCode(false);
  };

  return (
    <div style={{textAlign: 'center'}}>
      {/*<h4 style={{marginBottom: '16px'}}>クーポン使用</h4>*/}
      <label htmlFor="couponCodeName" className={classes.label}>{labelAdd}</label>
      <TextField
        // autoFocus
        variant={"outlined"}
        style={{fontSize: 'small'}}
        id="couponCodeName"
        name="couponCodeName"
        size={"small"}
        // label={labelAdd}
        // className={clsx(classes.margin)}
        className={classes.input}
        onChange={handleChange}
        type='text'
        value={couponData}
        fullWidth
        InputLabelProps={{
          shrink: true,
          name: 'couponCodeName',
        }}
      />
      <div className={classes.demo}>
        <List dense={dense}>
          {
            couponsCodeList && couponsCodeList.length !== 0 &&
            couponsCodeList.map((c, index) => {
              return (
                <ListItem key={index}>
                  <ListItemText
                    primary={c}
                  />
                  <ListItemIcon onClick={() => handleDeleteCode(c)}>
                    <Delete/>
                  </ListItemIcon>
                </ListItem>
              );
            })
          }
        </List>
        <Dialog
          open={openDialogDeleteCode}
          onClose={onCloseDialogDeleteCode}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{titleDelete}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {deleteTargetCode}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="default"
                    onClick={onCloseDialogDeleteCode}>{cancelButton}</Button>
            <Button variant="contained" color="secondary"
                    onClick={handleDoDelete}>{deleteButton}</Button>
          </DialogActions>
        </Dialog>
      </div>
      {_.isEmpty(couponData) ?
        null
        : loading ? <CircularProgress/>
          :
          <Button disabled={_.isEmpty(couponData)}
                  className={classes.buttonHandle} variant="contained"
                  onClick={onclickAddCoupon} color="primary">
            {buttonName}
          </Button>
      }
    </div>
  );
}

export default CartCoupons;
