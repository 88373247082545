import React, {memo} from 'react';
import {LOGIN_URI, REGISTER_URI} from '../../config/apiUrls';

const LoginRegister = () => {

  const onLogin = () => {
    window.location = LOGIN_URI + '?redirect_url=' + encodeURIComponent(window.location.href)
  }
  const onRegister = () => {
    window.location = REGISTER_URI + '?redirect_url=' + encodeURIComponent(window.location.href)
  };

  return (
    <div>
      <button
        className={'geniam-btn'}
        onClick={onLogin}
      >
        ログイン
      </button>
      <button
        className={'geniam-btn'}
        onClick={onRegister}
      >
        会員登録
      </button>
    </div>
  );
}

export default memo(LoginRegister);

