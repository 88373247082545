import React, {memo} from 'react';
import {Button, createStyles} from '@material-ui/core';
import store from '../store';
import FormattedMessage from "../context/FormatedMessage";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useGlobal} from "reactn";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        margin: 'auto',
        maxWidth: 1080,
        padding: theme.spacing(),
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    service: {
        fontWeight: "bold",
    },
    button: {
        margin: theme.spacing()
    }
}));

const steps = [
    {
        label: <FormattedMessage id='cart.step1'/>,
        path: '/'
    },
    {
        label: <FormattedMessage id='cart.step2'/>,
        path: '/delivery'
    },
    {
        label: <FormattedMessage id='cart.step3'/>,
        path: '/payment'
    },
    {
        label: <FormattedMessage id='cart.step4'/>,
        path: '/confirmation'
    }
]

function InformationBar(props) {
    const {step} = props;
    const history = useHistory();
    const classes = useStyles();
    const [user] = useGlobal('user');
    const [cart] = useGlobal('cart');

    const handleClick = (path) => {
        history.push(path);
    }
    return (
        <div className={classes.root}>
            <div style={{width: "100%", textAlign: "center"}}>
                <span className={classes.service}>{store.fromService || ""}</span>
                {
                    steps.map((s, idx) => (
                        <Button
                            className={classes.button}
                            variant={step >= idx ? "outlined" : "text"}
                            // color={step === idx ? "primary" : "default"}
                            style={{
                                background: (step === idx) ? "#8CC358" : "transparent",
                                color: step === idx ? "#fff" : "default",
                                border: "0"
                            }}
                            disabled={(idx === 1 && !store.isRequireDelivery()) || !user || !cart || (cart.length === 0)}
                            onClick={() => handleClick(s.path)}
                            key={idx}
                        >
                            {s.label}
                        </Button>
                    ))}
            </div>
        </div>
    );

}

export default memo(InformationBar);
