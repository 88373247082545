import * as APIList from './APIList';
import store from 'store'
import {toast} from "react-toastify";
import axios from "axios";

class API {
  public async getUserInfo(accessToken: string) {
    const res = await fetch(APIList.API_GET_USER_INFO, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
    if (res.status === 200) {
      return await res.json();
    } else {
      return undefined;
    }
  }

  public async refresh(refreshToken: string) {
    const res = await fetch(APIList.API_REFRESH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        refreshToken: refreshToken
      })
    });

    if (res.status === 200) {
      return await res.json();
    } else {
      return undefined;
    }
  }

  public async getDividedTime(accessToken: string) {
    if (!accessToken)
      return undefined;

    const res = await fetch(APIList.API_GET_PAYMENT_DIVIDED, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });

    if (res.status === 200) {
      return await res.json();
    } else {
      return undefined;
    }
  }

  public async logout(accessToken: string) {
    const res = await fetch(APIList.API_LOGOUT, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });

    return res.status === 200;
  }

  public async getProduct(productId: string) {
    try {
      const {data} = await axios.get(APIList.API_GET_PRODUCT(productId));
      return data
    } catch (e) {
      return undefined
    }
  }

  public async getProductYourPrice(accessToken: string, productId: string) {
    if (!accessToken)
      return undefined

    const res = await fetch(APIList.API_GET_PRODUCT_YOUR_PRICE(productId), {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    });
    if (res.status === 200) {
      return await res.json();
    } else {
      return undefined;
    }
  }

  public async getDeliveries(accessToken: string) {
    if (!accessToken)
      return [];

    const res = await fetch(APIList.API_LIST_DELIVERIES, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
    if (res.status === 200) {
      return await res.json();
    } else {
      return [];
    }
  }

  public async newDelivery(accessToken: string, data: any): Promise<any[]> {
    const res = await fetch(APIList.API_NEW_DELIVERY, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(data)
    });

    if (res.status === 200) {
      return [];
    } else if (res.status === 400) {
      const json = await res.json();
      return json.errors;
    } else {
      return await res.json();
    }
  }

  public async updateDelivery(accessToken: string, recordId: number, data: any): Promise<any[]> {
    const res = await fetch(APIList.API_DELIVERY(recordId), {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(data)
    });

    if (res.status === 200) {
      return [];
    } else if (res.status === 400) {
      const json = await res.json();
      return json.errors;
    } else {
      return await res.json();
    }
  }

  public async deleteDelivery(accessToken: string, recordId: number) {
    const res = await fetch(APIList.API_DELIVERY(recordId), {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    });

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  public async getCards(accessToken: string) {
    if (!accessToken)
      return []
    const res = await fetch(APIList.API_LIST_CARDS, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
    if (res.status === 200) {
      return await res.json();
    } else {
      return [];
    }
  }

  public async newCard(accessToken: string, source: string) {
    const res = await fetch(APIList.API_NEW_CARD, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        source: source
      })
    });

    if (res.status === 200) {
      return true;
    } else {
      const json = await res.json()
      toast.error(json.message);
      return false;
    }
  }

  public async deleteCard(accessToken: string, cardId: string) {
    const res = await fetch(APIList.API_CARD(cardId), {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  public async purchasePackage(accessToken: string, params: any): Promise<{ order: string, backLink: string, enecolorBook1: boolean, diagnosisURL: string }> {
    const res = await fetch(APIList.API_PURCHASE_PACKAGE, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(params)
    });

    if (res.status === 200) {
      const json = await res.json();
      store.remove('seichokuOptions')
      return json;
    } else {
      const json = await res.json();
      throw new Error(json.message || json.error_message || json);
    }
  }

  public async purchase(accessToken: string, params: any): Promise<{ order: string, backLink: string, enecolorBook1: boolean, diagnosisURL: string }> {
    const res = await fetch(APIList.API_PURCHASE, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(params)
    });

    if (res.status === 200) {
      const json = await res.json();
      store.remove('seichokuOptions')
      return json;
    } else {
      const json = await res.json();
      throw new Error(json.message || json.error_message || json);
    }
  }

  public async getPoints(accessToken: string): Promise<number> {
    const res = await fetch(APIList.API_POINTS, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    });

    if (res.status === 200) {
      const json = await res.json();
      return json.points;
    } else {
      const json = await res.json();
      throw new Error(json.message || json.error_message || json);
    }
  }

  public async getPref(): Promise<Array<{ pref_code: string, pref_name: string }>> {
    const res = await fetch(APIList.API_GET_PREF, {
      method: 'GET'
    });

    if (res.status === 200) {
      const json = await res.json();
      return json;
    } else {
      const json = await res.json();
      throw new Error(json.message || json.error_message || json);
    }
  }

  public async loginEmailAsync(email: string) {
    try {
      const {data} = await axios.post(APIList.LOGIN_EMAIL, {email});
      return data
    } catch (e) {
      return undefined
    }
  }
}

export default new API();
