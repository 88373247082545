import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import {CardElement, injectStripe, ReactStripeElements} from 'react-stripe-elements';
import API from '../common/API';
import store from '../store';

const styles = (theme: Theme) => createStyles({
  element: {
    borderBottom: '1px solid #999',
    marginBottom: theme.spacing(),
  }
});

interface IProps extends ReactStripeElements.InjectedStripeProps {
  onClose: (refresh: boolean) => () => void;
}

type Props = IProps & WithStyles<typeof styles>

interface IState {
  isProcessing?: boolean;
  errorMessage?: string;
}

class StripeInputCard extends React.Component<Props, IState> {
  public state = {
    isProcessing: false,
    errorMessage: ''
  }

  public render() {
    const { classes } = this.props;

    return (
      <Card style={{width: "370px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)"}}>
        <CardContent>
        <h3 style={{marginBottom: "10px", textAlign: "center"}}>クレジットカード情報の入力</h3>
          <CardElement className={classes.element} />
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <img src="/static/images/cards/American Express.png" height={24} alt=''/>
            <img src="/static/images/cards/Diners Club.png" height={24} alt=''/>
            <img src="/static/images/cards/Discover.png" height={24} alt=''/>
            <img src="/static/images/cards/JCB.png" height={24} alt=''/>
            <img src="/static/images/cards/MasterCard.png" height={24} alt=''/>
            <img src="/static/images/cards/Visa.png" height={24} alt=''/>
          </div>
          <Typography variant="body1">{this.state.errorMessage}</Typography>
        </CardContent>
        <CardActions className={"right"} style={{textAlign: "right", justifyContent: "flex-end"}}>
          <Button variant="contained" color="default" disabled={this.state.isProcessing}>キャンセル</Button>
          <Button variant="contained" color="primary" onClick={this.handleSubmit} disabled={this.state.isProcessing}>追加</Button>
        </CardActions>
      </Card>
    );
  }

  private handleSubmit = () => {
    this.setState({
      isProcessing: true
    });

    const stripe = this.props.stripe as ReactStripeElements.StripeProps;
    stripe.createToken({}).then((result) => {
      if (result.error) {
        this.setState({
          isProcessing: false,
          errorMessage: result.error.message
        })
      } else {
        (async () => {
          const token = result.token as stripe.Token;
          const ret = await API.newCard(store.accessToken as string, token.id);
          if (ret) {
            this.props.onClose(true)();
          }
        })();
      }
    });
  }
}

// @ts-ignore
export default injectStripe(withStyles(styles)(StripeInputCard));
