import API from '../../common/API';
import axios from 'axios';
import {API_GENIAM, API_PREFIX} from '../../config/apiUrls';
import {getGlobal, setGlobal} from 'reactn';
import _ from 'lodash';
import store from '../../store';

export const numOfTax = 0.1;
export const numOfInstallment = 8.16;
const KEY_CART = 'cart.cart';

const parseMethod = (value) => {
  if (value === "is_payone") {
    return "一括"
  }
  if (value === "is_installment") {
    return "分割"
  }
  if (value === "is_paymonth") {
    return "月払い"
  }
  if (value === "is_payyear") {
    return "年払い"
  }
  if (value === "is_transfer_banking") {
    return "銀行振込"
  }
}

const getEName = (value) => {
  if (value === "一括") {
    return "pay_one"
  }
  if (value === "分割") {
    return "installment"
  }
  if (value === "月払い") {
    return "pay_month"
  }
  if (value === "年払い") {
    return "pay_year"
  }
  if (value === "銀行振込") {
    return "transferBanking"
  }
}

export const cartProductData = async (newCart, productID, num, isLogin, newMetamorID) => {
  const accessToken = localStorage.getItem('accessToken') || [];
  let product = null;
  if (isLogin) {
    product = await API.getProductYourPrice(accessToken, productID);
  } else {
    product = await API.getProduct(productID);
  }

  if (product && !newCart.find(c => c.product_id === productID)) {
    const {payMethodObj, methodSelect} = getGlobal();

    if (!payMethodObj[productID]) {
      let defaultMethod = methodSelect;
      if (parseMethod(product.default_method) !== methodSelect) {
        defaultMethod = parseMethod(product.default_method);
        setGlobal({methodSelect: parseMethod(product.default_method)})
      }
      /// if default method is transferBanking, set store.selectedPaymentId = transferBanking
      if (parseMethod(product.default_method) === '銀行振込') {
        store.selectedPaymentId = 'transferBanking';
        store.store();
      }

      payMethodObj[productID] = {
        name: defaultMethod,
        selected: true,
        divided_times: product.divided_times,
        eName: getEName(defaultMethod)
      };
      setGlobal({payMethodObj: {...payMethodObj}});
    }

    newCart.push({
      product_id: productID,
      package_id: '',
      metamor_id: newMetamorID,
      num: num || 1,
      name: product.name,
      amount: Number(product.amount),
      require_delivery: product.require_delivery === '1',
      enable_stock: product.enable_stock === '1',
      stock: product.stock,
      max_num: product.max_num,
      appList: [],
      addedServiceList: [],
      payMethod: [],
      divided_times: product.divided_times,
      is_transfer_banking: product.is_transfer_banking,
      amount_month: product.amount_month,
      amount_year: product.amount_year,
      amount_installment: product.amount_installment,
      is_payone: product.is_payone,
      is_installment: product.is_installment,
      is_paymonth: product.is_paymonth,
      is_payyear: product.is_payyear,
      remarks: product?.remarks
    });
    return newCart;
  }
  return newCart;
};

export const cartPackageData = async (newCart, packageID, num, newMetamorID, method) => {
  //update global packages data
  const {packages} = getGlobal();
  let packageInfo = await axios.get(
    API_GENIAM + `/v1/services/get-packageByPackageID?packageID=${packageID}`);
  if (!packageInfo.data)
    return newCart;
  const packageData = packageInfo.data;

  if (!packages.find(pl => pl.id === packageData.id)) {
    packages.push({...packageData});
    setGlobal({packages: packages});
    localStorage.setItem('cart.packages', JSON.stringify(packages));
  }

  // push new cart to global data
  if (!newCart.find(c => c.package_id === packageData.id)) {
    // set payMethod to callback global
    const {payMethodList, payMethodObj, methodSelect} = getGlobal();

    if (!payMethodObj[packageID]) {
      payMethodObj[packageID] = {
        name: methodSelect,
        selected: true,
        divided_times: getDividedTimes(packageData.payMethod || []),
        eName: getEName(methodSelect)
      };
      setGlobal({payMethodObj: {...payMethodObj}});
    }
    if (!payMethodList[packageID]) {
      payMethodList[packageID] = packageData.payMethod;
      setGlobal({payMethodList: {...payMethodList}});
    }

    newCart.push({
      product_id: '',
      package_id: packageData.id,
      metamor_id: newMetamorID,
      num: num || 1,
      name: packageData.packageName || '',
      amount: Number(packageData.price),
      require_delivery: false,
      enable_stock: false,
      stock: 0,
      max_num: 100,
      appList: packageData.appList,
      addedServiceList: packageData.addedServiceList,
      payMethod: packageData.payMethod || [],
      method,
      divided_times: getDividedTimes(packageData.payMethod || []),
      is_transfer_banking: isTransferBanking(packageData.payMethod || []),
      is_payone: isPayOne(packageData.payMethod || []),
      is_installment: isInstallment(packageData.payMethod || []),
      is_paymonth: isPayMonth(packageData.payMethod || []),
      is_payyear: isPayYear(packageData.payMethod || []),
    });
    return newCart;
  }
  return newCart;
};

const isTransferBanking = (data = []) => {
  return _.filter(data, d => d.name === "銀行振込" && d.selected)?.length
}

const isPayOne = (data = []) => {
  return _.filter(data, d => d.name === "一括" && d.selected)?.length
}

const isInstallment = (data = []) => {
  return _.filter(data, d => d.name === "分割" && d.selected)?.length
}

const isPayMonth = (data = []) => {
  return _.filter(data, d => d.name === "月払い" && d.selected)?.length
}

const isPayYear = (data = []) => {
  return _.filter(data, d => d.name === "年払い" && d.selected)?.length
}

const getDividedTimes = (data = []) => {
  return _.filter(data, d => d.name === "分割" && d.selected)[0]?.times || 0
}
export const createTotalPricePackage = () => {
  const {cart} = getGlobal();
  const cartStore = JSON.parse(localStorage.getItem(KEY_CART));
  const cartPackages = JSON.parse(localStorage.getItem('cart.packages'));

  let packagePrice = {};
  cartStore.forEach(cartView => {
    if (cartView.package_id) {
      let priceArray = [];
      let indexPk = _.findIndex(cartPackages, {'id': cartView.package_id});
      let subTotal = parseInt(cartView.amount) * parseInt(cartView.num);
      let taxTotal = Math.round(subTotal * numOfTax);
      let allTotal = subTotal + taxTotal;
      let package_fee = allTotal * numOfInstallment / 100;
      let feePerMonth;
      let unitPrice;
      let beforeTax;
      let tax;
      let subFirstTemp;
      let firstTemp = 0;
      if (indexPk !== -1) {
        cartPackages[indexPk].payMethod.forEach(paymt => {
          if (paymt.name === '一括') {
            let priceT = {};
            priceT.name = '一括';
            priceT.price = cartView.amount;
            priceT.allTotal = allTotal;
            priceT.subTotal = subTotal;
            priceT.taxTotal = taxTotal;
            priceT.subFirstPay = allTotal;
            priceT.planExpDate = 'Pay at the moment';
            priceArray.push({...priceT});
          }
          if (paymt.name === '銀行振込') {
            let priceT = {};
            priceT.name = '銀行振込';
            priceT.price = cartView.amount;
            priceT.allTotal = allTotal;
            priceT.subTotal = subTotal;
            priceT.taxTotal = taxTotal;
            priceT.subFirstPay = allTotal;
            priceT.planExpDate = 'Payment by bank transfer';
            priceArray.push({...priceT});
          }
          if (paymt.name === '分割') {
            let priceT = {};
            if (!paymt.prices)
              return null;

            const oneTimePay = Number(paymt.prices);
            beforeTax = (cartView.amount - oneTimePay) / (Number(paymt.times) - 1);
            tax = Math.round((cartView.amount * numOfTax) / Number(paymt.times));

            unitPrice = beforeTax + tax;
            firstTemp = 0;
            subFirstTemp = unitPrice + firstTemp;
            feePerMonth = 0;
            priceT.name = '分割';
            priceT.price = oneTimePay;
            priceT.allTotal = allTotal;
            priceT.subTotal = unitPrice;
            priceT.taxTotal = tax;
            priceT.package_fee = package_fee;
            priceT.feePerMonth = feePerMonth;
            priceT.subFirstPay = oneTimePay + tax;
            priceT.divided_times = Number(paymt.times);
            priceT.planExpDate = `お支払い回数 ${paymt.times}回`;
            priceArray.push({...priceT});
          }

          if (paymt.name === '月々払い') {
            let priceT = {};
            if (cartPackages[indexPk].planExpDate === '1year') {
              beforeTax = Math.round(parseInt(cartView.amount) / 12 * parseInt(cartView.num));
              tax = Math.round(beforeTax * numOfTax);
              unitPrice = beforeTax + tax;
              firstTemp = allTotal - unitPrice * 12;
              subFirstTemp = unitPrice + firstTemp;
              feePerMonth = Math.round(package_fee / 12);
              priceT.name = '月々払い';
              priceT.price = cartView.amount;
              priceT.allTotal = allTotal;
              priceT.subTotal = unitPrice;
              priceT.taxTotal = tax;
              priceT.package_fee = package_fee;
              priceT.feePerMonth = feePerMonth;
              priceT.subFirstPay = subFirstTemp;
              priceT.planExpDate = `Pay monthly on 1 year`;
              priceArray.push({...priceT});
            } else if (cartPackages[indexPk].planExpDate === '2year') {
              beforeTax = Math.round(
                parseInt(cartView.amount) / 24 * parseInt(cartView.num));
              tax = Math.round(beforeTax * numOfTax);
              unitPrice = beforeTax + tax;
              firstTemp = allTotal - unitPrice * 24;
              subFirstTemp = unitPrice + firstTemp;
              feePerMonth = Math.round(package_fee / 24);
              priceT.name = '月々払い';
              priceT.price = cartView.amount;
              priceT.allTotal = allTotal;
              priceT.subTotal = unitPrice;
              priceT.taxTotal = tax;
              priceT.package_fee = package_fee;
              priceT.feePerMonth = feePerMonth;
              priceT.subFirstPay = subFirstTemp;
              priceT.planExpDate = `Pay monthly time on 2 year`;
              priceArray.push({...priceT});
            } else {
              let numPlanExpDate = _.parseInt(
                cartPackages[indexPk].planExpDate);
              beforeTax = numPlanExpDate ? Math.round(
                parseInt(cartView.amount) / numPlanExpDate *
                parseInt(cartView.num)) : cartView.amount;

              tax = Math.round(beforeTax * numOfTax);
              unitPrice = beforeTax + tax;
              firstTemp = numPlanExpDate ? allTotal - unitPrice * numPlanExpDate : unitPrice;
              subFirstTemp = unitPrice + firstTemp;
              feePerMonth = Math.round(package_fee / numPlanExpDate);
              priceT.name = '月々払い';
              priceT.price = cartView.amount;
              priceT.allTotal = allTotal;
              priceT.subTotal = unitPrice;
              priceT.taxTotal = tax;
              priceT.package_fee = package_fee;
              priceT.feePerMonth = feePerMonth;
              priceT.isPlanExpDate = numPlanExpDate ? "true" : "false";
              priceT.subFirstPay = numPlanExpDate ? subFirstTemp : unitPrice;
              priceT.planExpDate = `Pay for ${numPlanExpDate} times on ${numPlanExpDate} months`;
              priceArray.push({...priceT});
            }
          }
          packagePrice[`${cartView.package_id}`] = priceArray;
        });
      }
    }
  });

  cart.forEach(cartView => {
    if (cartView.product_id) {
      let priceArray = [];
      let indexPk = _.findIndex(cart, {'product_id': cartView.product_id});
      let subTotal = parseInt(cartView.amount) * parseInt(cartView.num);
      let taxTotal = Math.round(subTotal * numOfTax);
      let allTotal = subTotal + taxTotal;
      let package_fee = allTotal * numOfInstallment / 100;
      let feePerMonth;
      let unitPrice;
      let beforeTax;
      let tax;
      let subFirstTemp;
      let firstTemp = 0;

      if (indexPk !== -1) {
        if (cartView.is_payone) {
          let priceT = {};
          priceT.name = '一括';
          priceT.eName = "pay_one"
          priceT.price = cartView.amount;
          priceT.allTotal = allTotal;
          priceT.subTotal = subTotal;
          priceT.taxTotal = taxTotal;
          priceT.subFirstPay = allTotal;
          priceT.planExpDate = 'Pay at the moment';
          priceArray.push({...priceT});
        }
        if (cartView.is_transfer_banking) {
          let priceT = {};
          priceT.name = '銀行振込';
          priceT.eName = "transferBanking"
          priceT.price = cartView.amount;
          priceT.allTotal = allTotal;
          priceT.subTotal = subTotal;
          priceT.taxTotal = taxTotal;
          priceT.subFirstPay = allTotal;
          priceT.planExpDate = '銀行振り込み（一括払い）';
          priceArray.push({...priceT});
        }
        if (cartView.is_installment) {
          let priceT = {};
          if (cartView.amount_installment) {
            let subTotal = parseInt(cartView.amount_installment) * parseInt(cartView.num) * parseInt(cartView.divided_times);
            let taxTotal = Math.round(subTotal * numOfTax);
            let allTotal = subTotal + taxTotal;
            package_fee = allTotal * numOfInstallment / 100;
            beforeTax = cartView.amount_installment * parseInt(cartView.num);
            tax = Math.round(beforeTax * numOfTax);
            unitPrice = beforeTax + tax;
            firstTemp = allTotal - unitPrice;
            subFirstTemp = unitPrice + firstTemp;
          } else {
            beforeTax = Math.round(parseInt(cartView.amount) / cartView.divided_times * parseInt(cartView.num));
            tax = Math.round(beforeTax * numOfTax);
            unitPrice = beforeTax + tax;
            firstTemp = allTotal - unitPrice * cartView.divided_times;
            subFirstTemp = unitPrice + firstTemp;
          }

          feePerMonth = 0;
          priceT.name = '分割';
          priceT.eName = "installment"
          priceT.price = cartView.amount;
          priceT.allTotal = allTotal;
          priceT.subTotal = unitPrice;
          priceT.taxTotal = tax;
          priceT.package_fee = package_fee;
          priceT.feePerMonth = feePerMonth;
          priceT.subFirstPay = subFirstTemp;
          priceT.divided_times = Number(cartView.divided_times);
          priceT.planExpDate = `お支払い回数 ${cartView.divided_times}回`;
          priceArray.push({...priceT});
        }
        if (cartView.is_paymonth && cartView.amount_month) {
          let priceT = {};
          beforeTax = cartView.amount_month * parseInt(cartView.num);
          tax = Math.round(beforeTax * numOfTax);
          unitPrice = beforeTax + tax;
          firstTemp = allTotal - unitPrice * 24;
          subFirstTemp = unitPrice + firstTemp;
          feePerMonth = Math.round(package_fee / 24);
          priceT.name = '月払い';
          priceT.eName = "pay_month"
          priceT.price = cartView.amount_month * 12;
          priceT.allTotal = beforeTax;
          priceT.subTotal = unitPrice;
          priceT.taxTotal = tax;
          priceT.subFirstPay = subFirstTemp;
          priceT.package_fee = package_fee;
          priceT.feePerMonth = feePerMonth;
          priceT.subFirstPay = unitPrice;
          priceT.planExpDate = `Pay monthly time on 1 year`;
          priceArray.push({...priceT});
        }
        if (cartView.is_payyear && cartView.amount_year) {
          let priceT = {};
          beforeTax = cartView.amount_year * parseInt(cartView.num);
          tax = Math.round(beforeTax * numOfTax);
          unitPrice = beforeTax + tax;
          firstTemp = allTotal - unitPrice * 24;
          subFirstTemp = unitPrice + firstTemp;
          feePerMonth = Math.round(package_fee / 2);
          priceT.name = '年払い';
          priceT.eName = "pay_year"
          priceT.price = cartView.amount_year;
          priceT.allTotal = beforeTax;
          priceT.subTotal = unitPrice;
          priceT.taxTotal = tax;
          priceT.subFirstPay = subFirstTemp;
          priceT.package_fee = package_fee;
          priceT.feePerMonth = feePerMonth;
          priceT.subFirstPay = unitPrice;
          priceT.planExpDate = `Pay monthly time on 2 year`;

          priceArray.push({...priceT});
        }
        packagePrice[`${cartView.product_id}`] = priceArray;
      }
    }
  });

  setGlobal({
    totalPrice: {...packagePrice},
  });
  return {
    packagePrice,
  };
};

export const AffiliateAvailable = async (
  newMetamorID, newUserId, newUserEmail, checkPayPackage, checkPayCoupon) => {
  try {
    if (!newMetamorID && !newUserId && !newUserEmail)
      return null;
    let metamor;
    if (newMetamorID) {
      let {data} = await axios.get(
        API_PREFIX + `/geniam/checkAffiliated?metamorID=${newMetamorID}`);
      metamor = data;
    }
    if (newUserId && newUserEmail) {
      let {data} = await axios.get(API_PREFIX +
        `/geniam/checkUserMetamorAffiliate?userId=${newUserId}&userEmail=${newUserEmail}`);
      metamor = data;
    }

    if (!metamor)
      return null;

    if (checkPayPackage) {
      if (checkPayPackage !== Boolean(metamor.profile.package_affiliate))
        return null;
      return metamor;
    }
    if (checkPayCoupon) {
      return metamor;
    }

    if (Boolean(metamor.profile.product_affiliate) === false)
      return null;
    return metamor;
  } catch (e) {
    console.log(e.toString());
    return null;
  }
};

