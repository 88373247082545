import {Button, createStyles, Theme, WithStyles, withStyles} from '@material-ui/core';
import * as React from 'react';
import store from '../store';

const styles = (theme: Theme) => createStyles({
  content: {
    marginTop: '1em',
    marginBottom: '2em',
    textAlign: 'center'
  },
  caption: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#ec6319',
  },
  button: { width: '375px' },
});

interface IProps extends WithStyles<typeof styles> {};

class EnecolorBook1 extends React.Component<IProps> {
  public render() {
    const {classes} = this.props;
    return (
      <div className={classes.content}>
        <p>
          <span className={classes.caption}>
            相性のトリセツの<br/>
            診断は80問<br/>
            たったの8分
          </span>
        </p>
        <img src="/static/images/enecolor_lever.png" alt="enecolor_lever"/><br/>
        <Button color="primary" variant="contained" className={classes.button} onClick={this.handleClickButton}>診断はコチラ</Button>
      </div>
    )
  }

  private handleClickButton = () => {
    window.location.href = store.diagnosisURL;
  }
}

export default withStyles(styles)(EnecolorBook1);
