import {Button, Checkbox, createStyles, FormControlLabel, IconButton, TextField, Typography} from '@material-ui/core';
import * as React from 'react';
// @ts-ignore
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Cart2 from '../component/Cart2';
import CartSummary2 from '../component/CartSummary2';
import ErrorSnackbar from '../component/ErrorSnackbar';
import Footer from '../component/Footer';
import InformationBar from '../component/InformationBar';
import store from '../store';
import ICartItem from '../store/ICartItem';
import IDeliveryItem from '../store/IDeliveryItem';
import IPaymentMethod from '../store/IPaymentMethod';
import {getGlobal, setGlobal} from 'reactn'
import axios from 'axios'
import {API_PREFIX} from "../config/apiUrls";
import CartCoupons from "../component/CartCoupons";
import {Elements} from "react-stripe-elements";
import PaymentRequestForm from "../component/PaymentRequestForm";
import {getFlowPage} from "../actions/flowPage";
import FormattedMessage from '../context/FormatedMessage'
import _ from "lodash";
import ButtonAppBar from "../component/GeniamBar/MenuAppBar";
import {Edit} from "@material-ui/icons";
import {withStyles} from "@material-ui/styles";

const styles = () => createStyles({
    input: {
        background: "#F5F7FB",
        border: 0,
        borderRadius: "5px",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f5f5f5"
        }
    },
});

interface IState {
    cart?: ICartItem[];
    checkedReciept?: boolean
    delivery?: IDeliveryItem;
    payment?: IPaymentMethod;
    openSnackbar?: boolean;
    snackbarMessage?: string;
    disabledButton?: boolean;
    comment?: string;
}

const getTickets = async () => {
    try {
        const {data: {ticket}} = await axios.get(`${process.env.REACT_APP_API_URL}/tickets`)
        return ticket
    } catch (e) {
        console.log(e)
        return null
    }
}

const getFlowPageList = async () => {
    try {
        const {cart} = getGlobal()
        const flowList = []
        await Promise.all(cart.map(async c => {
            const productId = c.product_id || c.package_id
            const productName = c.name

            if (!productId)
                return null;

            const data = await getFlowPage(productId)
            if (data)
                flowList.push({id: productId, content: data, name: productName})
        }))

        return flowList
    } catch (e) {
        console.log(e)
        return []
    }
}

async function Paid(totalOfTickets: number) {
    const {subscriptions} = getGlobal()
    try {
        const {data} = await axios.post(API_PREFIX + `/subscriptions/paid`, {
            subs: subscriptions.map(s => s.id),
            totalOfTickets: totalOfTickets,
            service_name: "questum"
        })
        if (data)
            return true
    } catch (e) {
        console.log(e)
        return false
    }
}

class ConfirmationView extends React.Component<RouteComponentProps<{}>, IState> {

    public state = {
        cart: store.cart,
        checkedReciept: false,
        delivery: store.getSelectedDelivery(),
        payment: store.getSelectedPaymentMethod(),
        openSnackbar: false,
        snackbarMessage: '',
        disabledButton: false,
        comment: '',
    }

    public componentDidMount() {
        const {methodSelect} = getGlobal();
        let transferCartLength = _.filter(this.state.cart, {is_transfer_banking: 1})
        if ((store.selectedPaymentId === 'googlePay' || store.selectedPaymentId === 'applePay') || (store.selectedPaymentId === 'transferBanking' && transferCartLength?.length !== this.state.cart.length)) {
            store.selectedPaymentId = ''
            localStorage.setItem('cart.payment', '');
        }

        /// If payment method is installment and selectedPaymentId is transferBanking, reset selectedPaymentId to visa card
        if (methodSelect === '分割' && store.selectedPaymentId === 'transferBanking') {
            store.selectedPaymentId = store.paymentMethods[0].id || '';
            store.store();
        }

        if (store.cart.length === 0 || store.user == null) {
            this.props.history.push('/');
        } else {
            const next = store.next();
            if (next !== '/confirmation') {
                this.props.history.push(next);
            }
        }
        // refresh cart global
        store.refreshCart().then(() => {
            this.setState({
                cart: store.cart
            });
        });
    }

    public render() {
        const {classes}: any = this.props;
        const {delivery, payment, cart} = this.state;
        return (
            <div>
                <ButtonAppBar/>
                <div className={"div-content"}>
                    <InformationBar step={3}/>
                    <div className="flex-base">
                        <div className="flex-main">
                            <div className="panel">
                                <div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <Typography variant="h4" style={{fontWeight: "bold", fontSize: "30px"}}>
                                            <FormattedMessage id='cart.confirmation.cart'/>
                                        </Typography>
                                        <IconButton onClick={this.handleClickBack('/')}><Edit/></IconButton>
                                    </div>
                                    <Cart2
                                        cart={cart}
                                        editable={false}
                                        classes={{stockAlert: ""}}
                                        handleDelete
                                        handleChangeNum
                                        handleChangeMethod={this.handleChangeMethod}
                                    />
                                </div>

                                <div style={{marginTop: "20px"}}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <Typography variant="h4" style={{fontWeight: "bold", fontSize: "30px"}}>
                                            <FormattedMessage id='cart.payment.method'/>
                                        </Typography>
                                        <IconButton onClick={this.handleClickBack('/payment')}><Edit/></IconButton>
                                    </div>
                                    {
                                        payment ? (payment.id === 'applePay' || payment.id === 'googlePay' || payment.id === 'transferBanking') ?
                                                <div>
                                                    {payment.brand}<br/>
                                                    <img src={"static/images/cards/" + payment.id + ".png"} height={50}
                                                         alt=''/>
                                                </div>
                                                :
                                                <>
                                                    <p style={{fontSize: "16px"}}>
                                                        <FormattedMessage id='cart.confirmation.creditCard'/><br/>
                                                        <img
                                                            src={payment ? 'static/images/cards/' + payment.brand + '.png' : ''}
                                                            height={24} alt=''/>
                                                        {payment ? payment.brand : ''} <FormattedMessage
                                                        id='cart.payment.lastFourDigits'/> {payment ? payment.last4 : ''}<br/>
                                                        <FormattedMessage
                                                            id='cart.payment.expirationDate'/> {payment ? payment.exp_month : ''}/{payment ? payment.exp_year : ''}
                                                    </p>
                                                    {
                                                        store.isUseGeniamPoint ?
                                                            (
                                                                <p>
                                                                    <FormattedMessage
                                                                        id='cart.payment.geniamPoint'/>{store.usePoints()}
                                                                    <FormattedMessage
                                                                        id='cart.payment.point'/>
                                                                </p>
                                                            )
                                                            :
                                                            ''
                                                    }
                                                </>
                                            : <FormattedMessage id='cart.confirmation.payment.noselect'/>
                                    }

                                </div>

                                <div style={{marginTop: "20px"}}>
                                  <div style={{display: "flex", alignItems: "center"}}>
                                    <Typography variant="h4" style={{fontWeight: "bold", fontSize: "30px"}}>
                                      <FormattedMessage id='cart.confirmation.remarks'/>
                                    </Typography>
                                  </div>
                                  {
                                    cart.filter((c) => !_.isEmpty(c?.remarks)).length > 0 && cart.filter((c) => !_.isEmpty(c?.remarks)).map(((item, index) => {
                                      return (
                                        <div key={index}><strong>{item.name}</strong>: {item.remarks}</div>
                                      )
                                    }))
                                  }
                                  <p style={{marginTop: "20px"}}>
                                    <FormattedMessage id='cart.confirmation.aboutProduct'/>
                                  </p>
                                  <TextField
                                    fullWidth={true}
                                    multiline={true}
                                    rows={4}
                                    variant="outlined"
                                    value={this.state.comment}
                                    onChange={this.handleChangeComment}
                                    className={classes.input}
                                  />
                                </div>
                            </div>
                        </div>


                        <div className="flex-sub pl-20 mobile-pr-20">
                            <div className={"panel"}>

                                <div className="">
                                    <CartSummary2/>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                            />
                                        }
                                        label={<FormattedMessage id='cart.confirmation.issueReceipt'/>}
                                        name="reciept"
                                        value="1"
                                        checked={this.state.checkedReciept}
                                        onChange={this.handleCheckReciept}
                                    />
                                    <div className="centering m10">
                                        <Elements>
                                            <PaymentRequestForm/>
                                        </Elements>
                                    </div>
                                    <div className="centering m10">
                                        {
                                            window.location.pathname === '/confirmation' && (store.selectedPaymentId === 'applePay' || store.selectedPaymentId === 'googlePay') ?
                                                null
                                                :
                                                <Button variant="contained" color="primary"
                                                        onClick={this.handleClickOrder}
                                                        size={"large"}
                                                        style={{background: "#FB8800", fontSize: "18px", width: "100%", marginTop: "10px"}}
                                                        disabled={this.state.disabledButton}>{<FormattedMessage
                                                    id='cart.confirmation.confirmPurchase'/>}</Button>
                                        }
                                    </div>
                                </div>
                                {
                                    store.selectedPaymentId === 'googlePay' || store.selectedPaymentId === 'applePay' ?
                                        <div className="panel">
                                            <div style={{fontSize: '13px'}}><FormattedMessage
                                                id='cart.confirmation.notice'/></div>
                                        </div>
                                        :
                                        ''
                                }
                                {
                                    store.isRequireDelivery() ? (
                                        <div className="">
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <Typography variant="h4" style={{fontWeight: "bold", fontSize: "30px"}}>
                                                    <FormattedMessage id='cart.confirmation.address'/>
                                                </Typography>
                                                <IconButton
                                                    onClick={this.handleClickBack('/delivery')}><Edit/></IconButton>
                                            </div>
                                            <p style={{fontSize: "16px"}}>
                                                {delivery ? delivery.address_name : ''}<br/>
                                                〒{delivery ? delivery.address_zip : ''}<br/>
                                                {delivery ? delivery.address_state : ''} {delivery ? delivery.address_city : ''} {delivery ? delivery.address_street : ''}<br/>
                                                {delivery ? delivery.address_building : ''}<br/>
                                                ℡：{delivery ? delivery.tel : ''}
                                            </p>
                                        </div>
                                    ) : (<div/>)}

                                {
                                    window.location.pathname === "/confirmation" &&
                                    <div className="">
                                        <CartCoupons/>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    <ErrorSnackbar
                        open={this.state.openSnackbar}
                        onClose={this.handleCloseSnackbar}
                        message={this.state.snackbarMessage}
                    />
                </div>
                <Footer/>

            </div>
        );
    }

    private handleClickBack = (path: string) => () => {
        this.props.history.push(path);
    }

    private handleClickOrder = async () => {
        const {cart} = getGlobal()
        this.setState({
            disabledButton: true,
        });


        if (cart.length !== 0) {
            store.doPurchese(this.state.checkedReciept, this.state.comment)
                .then(async (result) => {
                    const flowPages = await getFlowPageList();
                    store.setThanks(result, flowPages);
                    //do paid questum if needed
                    const {questumPaid} = getGlobal()
                    if (questumPaid) {
                        let tickets = await getTickets()
                        console.log(tickets, parseInt(questumPaid))
                        if (tickets && tickets >= parseInt(questumPaid)) {
                            // do paid
                            let result = await Paid(parseInt(questumPaid))
                            if (result) setGlobal({doneQuestumPaid: true})
                        }
                    }
                    this.props.history.push('/thanks');
                })
                .catch((err) => {
                    this.setState({
                        openSnackbar: true,
                        snackbarMessage: err.message || err.error_message,
                        disabledButton: false,
                    })
                })
        }
    }

    private handleCheckReciept = (ev: React.ChangeEvent<{}>, checked: boolean) => {
        this.setState({
            checkedReciept: (ev.target as HTMLInputElement).checked
        });
    }

    private handleCloseSnackbar = () => {
        this.setState({
            openSnackbar: false
        });
    }

    private handleChangeComment = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            comment: ev.target.value.slice(0, 2000),
        });
    }

    private handleChangeMethod = (value: any) => {
        this.setState(
            {
                payment: store.getSelectedPaymentMethod()
            }
        )
    }
}

export default withRouter(withStyles(styles)(ConfirmationView));
