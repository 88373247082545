import {isProductionEnv} from "../config/env";
import {db} from "../firebaseConfig";


export const MailCustom = isProductionEnv ? 'EmailCustom' : 'EmailCustom-stg';


export const getFlowPage = async (id) => {
  try {
    const snapshot = await db.collection(MailCustom).doc(id).get()
    if (!snapshot.exists)
      return null

    return snapshot.data()?.flowPage || null
  } catch (e) {
    console.log(e);
    return null
  }
}
