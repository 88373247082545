import {useContext} from 'react';
import {LanguageContext} from "./LanguageContext";
import {isEmpty} from 'lodash'

function FormattedMessage({id}) {

  const messages = useContext(LanguageContext)
  if (!messages || isEmpty(messages))
    return id
  return messages[id] || id
}

export default FormattedMessage;
