import store from "../store";
import querrystring from "query-string";
import {getGlobal, setGlobal} from "reactn";
import axios from "axios";
import {API_GENIAM} from "../config/apiUrls";

const isPayYearOne = (annualProduct) => {
    let result = false;
    if (annualProduct) {
        return false
    }

    let yearPayCart = store.cart.filter(c => c.method === 'yearpay');
    if (store.selectedPaymentId !== 'googlePay' && store.selectedPaymentId !== 'applePay' && yearPayCart.length !== 0 && yearPayCart.length === store.cart.length)
        result = true;
    return result;
};


const byPlanExpDate = [
    {
        idPlan: '1year',
        namePlan: '1年',
    },
    {
        idPlan: '2year',
        namePlan: '2年',
    },
    {
        idPlan: '1month',
        namePlan: '1ヶ月',
    },
    {
        idPlan: '2month',
        namePlan: '2ヶ月',
    },
    {
        idPlan: '3month',
        namePlan: '3ヶ月',
    },
    {
        idPlan: '4month',
        namePlan: '4ヶ月',
    },
    {
        idPlan: '5month',
        namePlan: '5ヶ月',
    },
    {
        idPlan: '6month',
        namePlan: '6ヶ月',
    },
    {
        idPlan: '7month',
        namePlan: '7ヶ月',
    },
    {
        idPlan: '8month',
        namePlan: '8ヶ月',
    },
    {
        idPlan: '9month',
        namePlan: '9ヶ月',
    },
    {
        idPlan: '10month',
        namePlan: '10ヶ月',
    },
    {
        idPlan: '11month',
        namePlan: '11ヶ月',
    },
    {
        idPlan: '12month',
        namePlan: '12ヶ月',
    },
    {
        idPlan: '13month',
        namePlan: '13ヶ月',
    },
    {
        idPlan: '14month',
        namePlan: '14ヶ月',
    },
    {
        idPlan: '15month',
        namePlan: '15ヶ月',
    },
    {
        idPlan: '16month',
        namePlan: '16ヶ月',
    },
    {
        idPlan: '17month',
        namePlan: '17ヶ月',
    },
    {
        idPlan: '18month',
        namePlan: '18ヶ月',
    },
    {
        idPlan: '19month',
        namePlan: '19ヶ月',
    },
    {
        idPlan: '20month',
        namePlan: '20ヶ月',
    },
    {
        idPlan: '21month',
        namePlan: '21ヶ月',
    },
    {
        idPlan: '22month',
        namePlan: '22ヶ月',
    },
    {
        idPlan: '23month',
        namePlan: '23ヶ月',
    },
    {
        idPlan: '24month',
        namePlan: '24ヶ月',
    },
];


const getServices = async () => {
    let url = window.location.search.substr(1);
    const query = querrystring.parse(url);
    if (query.service_name === 'questum')
        setGlobal({questumPaid: query.taltailCoin});
    // localStorage.setItem('oldPaidQuestum', JSON.stringify(query))
};

const getServicesList = async () => {
    try {
        let {data} = await axios.get(API_GENIAM + '/v1/services/serviceList');
        if (data)
            await setGlobal({servicesList: data});
    } catch (e) {
        console.log(e.toString());
    }
};

const getInstallmentPayment = () => {
    const {payMethodObj} = getGlobal();

    let methods = [];

    /// Parse payMethodObj to array with object value
    Object.keys(payMethodObj).forEach((key) => {
        if (typeof payMethodObj[key] === 'boolean')
            return;

        methods.push(payMethodObj[key]);
    });

    let installment = null;

    /// Get same installment payment
    methods.forEach((method, index) => {
        const nextMethod = methods[index + 1] || null;

        /// if method is installment payment and has divided_times, init installment value
        if (method.name === "分割" && method.divided_times)
            installment = {
                divided_times: method.divided_times,
                eName: method.eName,
                planExpDate: method.planExpDate,
                name: method.name,
            };

        /// Return if nextMethod is null
        if (!nextMethod)
            return;

        /// if nextMethod has divided_times and divided_times > installment.divided_times, set installment to nextMethod
        if (method.divided_times < nextMethod.divided_times)
            installment = {
                divided_times: nextMethod.divided_times,
                eName: nextMethod.eName,
                planExpDate: nextMethod.planExpDate,
                name: nextMethod.name,
            };
        /// if nextMethod is not installment payment, set installment to null
        if (method.name !== "分割")
            installment = null;

        /// if nextMethod is installment payment but has divided_times < installment.divided_times, set installment to null
        if ((nextMethod && nextMethod.name !== "分割"))
            installment = null;
    });

    return installment;
}

export {
    isPayYearOne,
    byPlanExpDate,
    getServicesList,
    getServices,
    getInstallmentPayment
}
