import * as querystring from 'querystring';
import API from '../common/API';
import ICartItem from './ICartItem';
import IDeliveryItem from './IDeliveryItem';
import IPaymentMethod from './IPaymentMethod';
import {getGlobal, setGlobal} from 'reactn'
import {removeCookie, setCookie} from "../common/cookies";
import _ from 'lodash'
import Cookies from "js-cookie"
import {refreshToken} from "../actions/user";
import {ACCESS_TOKEN_KEY, API_GENIAM, GENIAM_REFRESH_TOKEN} from "../config/apiUrls";
// import {array, instanceOf} from "prop-types";
import axios from "axios";
import {getCouponsInfo} from "../actions/coupon";
import {AffiliateAvailable, cartPackageData, cartProductData} from "./acctions/getDataCart";
import {LAST_LOGIN_KEY} from "../config/constant";
import storeAccount from "../common/storeAccounts";
// import {Simulate} from "react-dom/test-utils";
// import error = Simulate.error;

const KEY_ACCESSTOKEN = 'accessToken';
const KEY_REFRESHTOKEN = 'refreshToken';
const KEY_CART = 'cart.cart';
const KEY_DELIVERY = 'cart.delivery';
const KEY_PAYMENT = 'cart.payment';
const KEY_FROM_SERVICE = 'cart.fromService';
const KEY_SELECTED_TIME = 'cart.selectedTime';

class Store {
  public accessToken: string | null = null;
  public refreshToken: string | null = null;
  public cart: ICartItem[] = [];
  public deliveries: IDeliveryItem[] = [];
  public paymentMethods: IPaymentMethod[] = [];
  public user: any;
  // @ts-ignore
  public selectedDeliveryId: number;
  // @ts-ignore
  public selectedPaymentId: string;
  // @ts-ignore
  public orderNumber: string;
  public affiliateMetamorId: string;
  // @ts-ignore
  public backLink: string;
  // @ts-ignore
  public fromService: string;
  // @ts-ignore
  public selectedTime: string;
  public isUseGeniamPoint = false;
  public isPackage = false;
  public useGeniamPointAmount = 0;
  public currentPointAmount = 0;
  public redirectURL: string | null = null;
  // @ts-ignore
  public diagnosisURL: string;
  public flowPages: any;
  public enecolorBook1: boolean = false;
  public banktransferName = "銀行振込"
  public canMakeRequest = null;
  public canRequestApple = true;
  public canRequestGoogle = true;
  public method: string;
  public hasDivided: any;

  public store() {
    localStorage.setItem(KEY_ACCESSTOKEN, this.accessToken || '');
    localStorage.setItem(KEY_REFRESHTOKEN, this.refreshToken || '');
    localStorage.setItem(KEY_CART, JSON.stringify(this.cart));
    localStorage.setItem(KEY_DELIVERY, this.selectedDeliveryId.toString());
    localStorage.setItem(KEY_PAYMENT, this.selectedPaymentId);
    localStorage.setItem(KEY_FROM_SERVICE, this.fromService || '');
    localStorage.setItem(KEY_SELECTED_TIME, this.selectedTime || '');
  }

  public restore() {
    this.accessToken = localStorage.getItem(KEY_ACCESSTOKEN);
    this.refreshToken = localStorage.getItem(KEY_REFRESHTOKEN);
    this.cart = JSON.parse(localStorage.getItem(KEY_CART) || '[]');
    this.selectedDeliveryId = Number.parseInt(localStorage.getItem(KEY_DELIVERY) || '0', 10);
    this.selectedPaymentId = localStorage.getItem(KEY_PAYMENT) || '';
    this.fromService = localStorage.getItem(KEY_FROM_SERVICE) || '';
    this.selectedTime = localStorage.getItem(KEY_SELECTED_TIME) || '';
  }

  public isLogin(result?: boolean) {
    if (result)
      return result

    return this.user !== undefined;
  }

  public methodsDefaults = [
    {
      type: 'applePay',
      id: 'applePay',
      brand: 'Apple Pay',
      last4: null,
      exp_month: null,
      exp_year: null
    },
    {
      type: 'googlePay',
      id: 'googlePay',
      brand: 'Google Pay',
      last4: null,
      exp_month: null,
      exp_year: null
    },
    {
      type: 'transferBanking',
      id: 'transferBanking',
      brand: '銀行振込',
      last4: null,
      exp_month: null,
      exp_year: null
    }]

  public async login() {
    try {
      let user = await API.getUserInfo(this.accessToken as string);
      if (!user) {
        //refresh token if token error
        const token = await refreshToken()
        if (token) {
          this.accessToken = token.accessToken;
          this.store();
          user = await API.getUserInfo(this.accessToken as string);
        }
      }
      if (user?.email) {
        localStorage.setItem(LAST_LOGIN_KEY, user?.email)
        storeAccount(user?.email)
      }
      setGlobal({
        user
      })
      this.user = user;
    } catch (e) {
      console.log(e.toString())
    }
  }

  public async logout() {
    if (await API.logout(this.accessToken as string)) {
      this.accessToken = '';
      this.refreshToken = '';
      this.user = undefined;
      localStorage.removeItem(ACCESS_TOKEN_KEY)
      Cookies.remove(GENIAM_REFRESH_TOKEN)
      window.location.href = `https://${process.env.REACT_APP_COGNITO_DOMAIN_NAME}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_SIGN_OUT_URL}`
      return true;
    } else {
      return false;
    }
  }

  public async addCart(productId: string, metamorId: string, userId: string, userEmail: string, num: number = 1, checkPayPackage, checkPayCoupon, method: string) {
    // checked metamor affiliate
    const metamorInfoChecked = await AffiliateAvailable(metamorId, userId, userEmail, checkPayPackage, checkPayCoupon)

    if (metamorInfoChecked === null)
      this.affiliateMetamorId = ''
    else
      this.affiliateMetamorId = metamorInfoChecked.uuid

    // if payment for package

    if (checkPayPackage) {
      const newCart = await cartPackageData(this.cart, productId, num, this.affiliateMetamorId, method)
      return this.cart = newCart
    }

    // if payment for coupon by email
    if (checkPayCoupon) {
      const couponCodeData = []
      await this.login()

      // check coupon code available
      let couponInfo = await getCouponsInfo([productId], this.user.user_id)
      if (couponInfo.length === 0)
        return this.cart

      let couponCode = await axios.get(API_GENIAM + `/v1/services/get-couponByCodeName?codeName=${productId}`)
      if (!couponCode.data)
        return this.cart

      await couponCodeData.push(couponCode.data)

      let couponTypes = couponCode.data.typesUsed

      // if just have coupon type apps
      if (couponTypes.apps.length !== 0 && couponTypes.packages.length === 0 && couponTypes.products.length === 0) {

        // do something
        let {data} = await axios.post(API_GENIAM + '/v1/services/appCode', {
          code: productId
        })
        if (!data)
          return this.cart

        // do something to code added
        let couponCodeList = [{
          code_id: productId,
          typesUsed: {
            apps: [...data.apps]
          }
        }]
        await setGlobal({
          couponCodeList: [...couponCodeList],
        })
        // await setGlobal({appCodeAdded: data.apps})
        return this.cart
      } else {
        // app code will auto added when purchase
        if (couponTypes.apps.length !== 0) {
          // do nothing
        }

        //if coupon type as packages
        if (couponTypes.packages.length !== 0) {
          await Promise.all(couponTypes.packages.map(async p => {
            await cartPackageData(this.cart, p.packageID, num, this.affiliateMetamorId, method)
          }))
        }

        //if coupon type as product
        if (couponTypes.products.length !== 0) {
          await Promise.all(couponTypes.products.map(async pro => {
            await cartProductData(this.cart, pro.product_id, num, this.isLogin(), this.affiliateMetamorId)
          }))
        }
        await setGlobal({
          couponCodeList: [...couponCodeData],
          couponsCodeList: [productId]
        })
        await localStorage.setItem('couponsCodes', JSON.stringify([productId]))
        return this.cart
      }
    }

    // if payment for product
    const newCart = await cartProductData(this.cart, productId, num, null, this.affiliateMetamorId)
    return this.cart = newCart
  }

  // refresh cart , reload
  public async refreshCart() {
    try {
      const newCart: ICartItem[] = [];

      await Promise.all(this.cart.map(async (c) => {
        if (_.isEmpty(c.product_id)) {
          let cartPackage = await cartPackageData(newCart, c.package_id, c.num, c.metamor_id, c.method)
          _.concat(newCart, cartPackage)
        } else {
          let cartProduct = await cartProductData(newCart, c.product_id, c.num, this.isLogin(), c.metamor_id)
          _.concat(newCart, cartProduct)
        }
      }));

      this.cart = newCart;
      await setGlobal({cart: [...newCart]})
      this.store();

    } catch (e) {
      console.log(e.toString())
    }
  }

  public next() {
    const {totalAll, discountAll} = getGlobal()
    let total = totalAll - discountAll;

    if (this.getSelectedDelivery() == null && this.isRequireDelivery()) {
      return '/delivery';
    } else if (total > 0 && (this.getSelectedPaymentMethod() == null || window.location.pathname === "/" || window.location.pathname === "/delivery")) {
      return '/payment';
    } else {
      return '/confirmation';
    }
  }

  public async init() {
    const query = querystring.parse(window.location.search.substr(1));
    if (query.token && !(query.token instanceof Array)) {
      this.accessToken = query.token;
    }
    if (query.refreshToken && !(query.refreshToken instanceof Array)) {
      this.refreshToken = query.refreshToken;
      setCookie(GENIAM_REFRESH_TOKEN, query.refreshToken)
    }
    if (query.add && !(query.add instanceof Array)) {
      const num = (query.num && !(query.num instanceof Array)) ? Number.parseInt(query.num, 10) : 1;
      const checkPayPackage = query.type === 'packages';
      const checkPayCoupon = query.type === 'coupon';
      const metamorID = (query.metamor && !(query.metamor instanceof Array)) ? query.metamor : '';
      const userID = (query.userId && !(query.userId instanceof Array)) ? query.userId : '';
      const userEmail = (query.userEmail && !(query.userEmail instanceof Array)) ? query.userEmail : '';
      this.method = (query.method && !(query.method instanceof Array)) ? query.method : '';
      await this.addCart(query.add, metamorID, userID, userEmail, num, checkPayPackage, checkPayCoupon, this.method);
    }
    if (query.service_name && !(query.service_name instanceof Array)) {
      this.fromService = ['Enecolor', 'InnerTours', 'InsightMind', 'Idamon'].find(c => c.toLocaleLowerCase() === query.service_name) || '';
    }
    if (query.selected_time && !(query.selected_time instanceof Array)) {
      this.selectedTime = query.selected_time;
    }
    if (query.redirect_url && !(query.redirect_url instanceof Array)) {
      this.redirectURL = query.redirect_url;
    }

    await this.login();

    // delivery list 取得
    this.deliveries = await API.getDeliveries(this.accessToken as string);
    if (this.deliveries.length > 0 && this.getSelectedDelivery() == null) {
      this.selectedDeliveryId = this.deliveries[0].record_id;
    }

    // get Payment Divided Time
    this.hasDivided = await API.getDividedTime(this.accessToken as string);

    // payment method list 取得
    this.paymentMethods = await API.getCards(this.accessToken as string);
    if (this.getSelectedPaymentMethod() == null) {
      if (this.paymentMethods.length === 0) {
        this.selectedPaymentId = null;
        // this.selectedPaymentId = 'transferBanking'
      } else {
        this.selectedPaymentId = this.paymentMethods[0].id;
      }
    }

    // points 取得
    // this.currentPointAmount = await API.getPoints(this.accessToken as string);

    this.isLogin(true)
    this.store();
  }

  public getDelivery(recordId: number) {
    return this.deliveries.find((d) => d.record_id === recordId);
  }

  public async updateDeliveries() {
    this.deliveries = await API.getDeliveries(this.accessToken as string);
    if (this.deliveries.length > 0 && this.getSelectedDelivery() == null) {
      this.selectedDeliveryId = this.deliveries[0].record_id;
    }
  }

  public async updatePaymentMethods() {
    this.paymentMethods = await API.getCards(this.accessToken as string);
    if (this.paymentMethods.length > 0 && this.getSelectedPaymentMethod() == null) {
      this.selectedPaymentId = this.paymentMethods[0].id;
    }
  }

  public getSelectedDelivery() {
    if (!this.isRequireDelivery()) {
      return undefined;
    }
    return this.deliveries.find((delivery) => delivery.record_id === this.selectedDeliveryId)
  }

  public getSelectedPaymentMethod() {
    return this.paymentMethods.find((paymentMethod) => paymentMethod.id === this.selectedPaymentId) || this.methodsDefaults.find((paymentMethod) => paymentMethod.id === this.selectedPaymentId)
  }

  public calcSubtotal() {
    let mtotal = this.cart.reduce((t, c) => t + c.num * c.amount, 0);
    mtotal = Math.round(mtotal * 110 / 100);
    return mtotal;
  }

  public calcFee() {
    // @ts-ignore
    const option = getGlobal().deliveryOption
    if (!option) return 0
    return parseInt(option.amount || "0");
  }

  public usePoints() {
    return this.isUseGeniamPoint ? this.useGeniamPointAmount : 0;
  }

  public calcTotal() {
    return this.calcSubtotal() + this.calcFee() - this.usePoints();
  }

  public setThanks(json: any, flowPages: any) {
    this.orderNumber = json.order;
    this.backLink = json.backLink;
    this.enecolorBook1 = json.enecolorBook1 || false;
    this.diagnosisURL = json.diagnosisURL || undefined;
    this.flowPages = flowPages || [];
  }

  public isRequireDelivery() {
    let requireDelivery = false;
    this.cart.forEach((c) => {
      if (c.require_delivery) {
        requireDelivery = true;
      }
    });

    return requireDelivery;
  }

  public checkStock() {
    let stockable = true;
    this.cart.forEach((c) => {
      if (c.enable_stock && c.stock < c.num) {
        stockable = false;
      }
    });

    return stockable;
  }

  public getSeichokuByProductId() {
    const {seichokuOptions} = getGlobal()
    let seichokuByProductId = Object
      .keys(seichokuOptions)
      .map(k => {
        const checked = seichokuOptions[k].find(opt => opt.checked)
        return Object.assign({}, checked, {product_id: k})
      })
    seichokuByProductId = _.keyBy(seichokuByProductId, 'product_id')
    return seichokuByProductId
  }

  public async doPurchese(receipt: boolean, comment: string): Promise<{ order: string, backLink: string, enecolorBook1: boolean, diagnosisURL: string }> {
    try {
      const delivery = this.getSelectedDelivery();
      const card = this.getSelectedPaymentMethod();
      const {totalByProductId, payMethodObj, couponsCodeList} = getGlobal()
      const seichokuByProductId = this.getSeichokuByProductId()
      const {totalAll, discountAll} = getGlobal()
      let total = totalAll - discountAll;

      if (card || total === 0) {
        let cart = []
        let packages = []
        this.cart && this.cart.length !== 0 && this.cart.map((c) => {
          if (c.product_id)
            cart.push(
              {
                product_id: c.product_id,
                num: c.num,
                metamor_id: c.metamor_id,
                /// If not check transferBanking error here because payMethodObj[c.product_id] is undefined with transferBanking
                /// Todo: Add transferBanking data init to payMethodObj or not do this if not need
                payment_method: (this.selectedPaymentId === "transferBanking" || payMethodObj[c.product_id]?.eName === 'transferBanking') ? this.banktransferName : payMethodObj[c.product_id].eName
              }
            )
          if (c.package_id) {
            packages.push(
              {
                package_id: c.package_id,
                num: c.num,
                metamor_id: c.metamor_id,
                payMethod: {name: this.selectedPaymentId === "transferBanking" ? this.banktransferName : payMethodObj[c.package_id].name}
              }
            )
          }
          return null
        })
        const params = {
          type: 'normal',
          source: card?.id,
          address: delivery ? delivery : null,
          cart,
          packages,
          coupons: couponsCodeList,
          receipt: receipt,
          // deliveryOption: getGlobal().deliveryOption,
          // deliveryCharge: this.calcFee(),
          seichokuByProductId,
          totalByProductId: totalByProductId,
          // deliveryOption: getGlobal().deliveryOption,
          // deliveryCharge: this.calcFee(),
          comment: comment,
          selected_time: this.selectedTime,
        }

        await this.login();
        try {
          const json = await API.purchase(this.accessToken as string, params)
          this.cart = [];
          this.selectedTime = '';
          removeCookie('seichokuOptions')
          await localStorage.setItem('couponsCodes', JSON.stringify([]))
          this.store();
          return json;
        } catch (error) {
          throw error;
        }
      } else {
        throw new Error('payment method is not selected');
      }
    } catch (e) {
      console.log(e.toString())
      throw new Error(e);
    }
  }

  public async doPaymentRequest(source: string, address?: any): Promise<{ order: string, backLink: string }> {
    const {payMethodObj, couponsCodeList} = getGlobal()

    let cart = []
    let packages = []
    this.cart && this.cart.length !== 0 && this.cart.map((c) => {
      if (c.product_id)
        cart.push(
          {
            product_id: c.product_id,
            num: c.num,
            metamor_id: c.metamor_id,
            /// If not check transferBanking error here because payMethodObj[c.product_id] is undefined with transferBanking
            /// Todo: Add transferBanking data init to payMethodObj or not do this if not need
            payment_method: (this.selectedPaymentId === "transferBanking" || payMethodObj[c.product_id].eName === 'transferBanking') ? this.banktransferName : payMethodObj[c.product_id].eName
          }
        )
      if (c.package_id) {
        packages.push(
          {
            package_id: c.package_id,
            num: c.num,
            metamor_id: c.metamor_id,
            payMethod: {name: this.selectedPaymentId === "transferBanking" ? this.banktransferName : payMethodObj[c.package_id].name}
          }
        )
      }
      return null
    })
    // @ts-ignore
    const params = {
      type: 'paymentrequest',
      source: source,
      address: address,
      seichokuByProductId: this.getSeichokuByProductId(),
      // @ts-ignore
      totalByProductId: getGlobal().totalByProductId,
      comment: "",
      receipt: true,
      selected_time: "",
      cart,
      packages,
      coupons: couponsCodeList,
    };

    await this.login();
    try {
      const json = await API.purchase(this.accessToken as string, params);
      this.cart = [];
      await localStorage.setItem('couponsCodes', JSON.stringify([]))
      this.store();
      return json;
    } catch (error) {
      throw error;
    }
  }
}

export default new Store();
