import {Button, Typography} from '@material-ui/core';
import * as React from 'react';
import EnecolorBook1 from '../component/EnecolorBook1';
import Footer from '../component/Footer';
import store from '../store';
import {getGlobal} from 'reactn'
import FormattedMessage from "../context/FormatedMessage";
import ButtonAppBar from "../component/GeniamBar/MenuAppBar";

class ThanksView extends React.Component<any, any> {
  public render() {
    return (
      <div>
        <ButtonAppBar/>
        <div className={"div-content"}>
          <div className="flex-base">
            <div className="flex-main">
              <div className="panel">
                <div className="centering">
                  <Typography variant="h4"><FormattedMessage id='cart.thanks.orderConfirmed'/></Typography>
                  <Typography variant="body1">
                    <FormattedMessage id='cart.thanks.orderConfirmedEmail'/><br/>
                    <strong><FormattedMessage id='cart.thanks.orderNumber'/>：{store.orderNumber}</strong>
                  </Typography>
                  <div className="centering m10">
                    <Button variant="contained" color="primary" onClick={this.handleClickBack}>
                      <FormattedMessage id='cart.thanks.return'/>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {store.enecolorBook1 ? <EnecolorBook1/> : ''}
          {
            store.flowPages ? <div className={"div-content"}>
                <div className="flex-base">
                  <div className="flex-main">
                    {
                      store.flowPages.map((page, index) => {
                        return (
                          <div key={index} className="panel">
                            <Typography variant="body1">
                              <strong>{page.name}</strong>
                            </Typography>
                            <div dangerouslySetInnerHTML={{__html: page.content || ''}}/>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              : ""
          }
        </div>
        <Footer/>
      </div>
    );
  }

  private handleClickBack = () => {
    const {doneQuestumPaid} = getGlobal()
    const thanksToast = doneQuestumPaid ? '&donePaid=true' : ''
    const link = store.redirectURL || store.backLink
    const mark = link && link.includes('?') ? '&' : "?"

    if (!store.redirectURL && !store.backLink)
      return window.location.href = '/'

    window.location.href = (store.redirectURL || store.backLink) + mark + 'token=' + store.accessToken + '&refreshToken=' + store.refreshToken + thanksToast;
  }
}

export default ThanksView;
