import {Button, Typography} from '@material-ui/core';
import * as React from 'react';
// @ts-ignore
import {RouteComponentProps, withRouter} from 'react-router-dom';
import CartSummary2 from '../component/CartSummary2';
import Footer from '../component/Footer';
import InformationBar from '../component/InformationBar';
import store from '../store';
import ICartItem from '../store/ICartItem';
import {setGlobal} from 'reactn'
import Cart2 from "../component/Cart2";
import FormattedMessage from "../context/FormatedMessage";
import ButtonAppBar from "../component/GeniamBar/MenuAppBar";

type Props = RouteComponentProps<{}>;

interface IState {
  cart: ICartItem[];
}

class CartView extends React.Component<Props, IState> {
  public state = {
    cart: store.cart,
    login: store.isLogin(),
  }

  public componentDidMount() {
    this.setState({
      cart: store.cart,
    });
  }

  public render() {
    const {cart} = this.state;
    return (
      <div>
        <ButtonAppBar/>
        <div className={"div-content"}>
          <InformationBar step={0}/>
          <div className="flex-base">
            <div className="flex-main">
              <div className="panel">
                <div className="centering"><Typography variant="h4"><FormattedMessage id='home.cartInfo'/></Typography>
                </div>
                {/*<Cart*/}
                {/*  cart={cart}*/}
                {/*  handleChangeNum={this.handleChangeNum}*/}
                {/*  handleDelete={this.handleDelete}*/}
                {/*/>*/}
                <Cart2
                  cart={cart}
                  editable={true}
                  // classes={{stockAlert: ""}}
                  handleDelete={this.handleDelete}
                  handleChangeNum={this.handleChangeNum}
                  handleChangeMethod={() => {
                  }}
                />
                <div className="m10 right">
                  <Button variant="contained" color="primary" onClick={this.handleClickSubmit}><FormattedMessage
                    id='home.confirm'/></Button>
                </div>
              </div>
            </div>
            <div className="flex-sub">
              <div className="panel">
                <div className="centering"><Typography variant="h4"><FormattedMessage id='home.subtotal'/></Typography>
                </div>
                {/*<CartSummary />*/}
                <CartSummary2/>
                {/*<CartOptions cart={cart}/>*/}
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }

  private handleChangeNum = (id: string) => {
    return (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newCart = this.state.cart.map((c) => {
        if (c.product_id === id || c.package_id === id) {
          c.num = Number.parseInt(ev.target.value, 10);
          if (c.num < 0 || Number.isNaN(c.num)) {
            c.num = 0;
          }
          /* if (c.num > 10) { c.num = 10; } */
          if (c.num > (c.max_num || 10)) {
            c.num = c.max_num;
          }
        }

        return c;
      })

      store.cart = newCart;
      store.store();

      setGlobal({cart: newCart})

      this.setState({
        cart: newCart,
      });
    }
  }

  private handleDelete = (id: string) => {
    return () => {
      const newCart = this.state.cart.reduce<ICartItem[]>((pre, c) => {
        if (id === c.product_id || id === c.package_id) {
          return pre;
        } else {
          pre.push(c);
          return pre;
        }
      }, []);

      store.cart = newCart;
      store.store();
      store.refreshCart();

      setGlobal({cart: newCart})

      this.setState({
        cart: newCart,
      });
    }
  }

  private handleClickSubmit = () => {
    const newCart = this.state.cart.reduce<ICartItem[]>((pre, c) => {
      if (c.num === 0) {
        return pre;
      } else {
        pre.push(c);
        return pre;
      }
    }, []);

    setGlobal({cart: newCart})
    store.cart = newCart;
    store.store();

    this.props.history.push('/');
  }
}

export default withRouter(CartView);
