import React, {createContext, useEffect, useState} from 'react';
import Fire from '../firebaseConfig';
import moment from 'moment';
import {useGlobal} from 'reactn';
import _ from 'lodash';
import LangSetting from '../component/LangSetting';

export const LanguageContext = createContext();

export const LanguageProvider = ({children}) => {

  const msg = useMessages();
  if (_.isEmpty(msg))
    return <LangSetting/>;
  return (
    <LanguageContext.Provider value={msg}>
      {children}
    </LanguageContext.Provider>
  );
};

const useMessages = () => {
  const [user] = useGlobal('user')
  const [langDefault] = useGlobal('langDefault');
  const lang = user && user?.language ? user.language : langDefault;
  const [m, setM] = useState(null);

  useEffect(() => {
    if (!lang) return;
    Fire.firestore().doc(`/intl/cartIntl/langColl/${lang.toLowerCase()}`).get().then(doc => {
      // console.log('m loaded', doc.data())
      setM({...doc.data()});
    }).catch(e => {
      // FirebaseError: Failed to get document because the client is offline
      window.location.reload();
    });
    let mLocale = lang.split('-', 1);
    if (mLocale === 'en' || mLocale.includes('en')) mLocale = 'en-gb';
    import(`moment/locale/${mLocale}`).then(obj => {
      moment.locale(mLocale);
    }).catch(e => console.error('import lang error,', e));
  }, [lang, user]);

  return m;
};
