import React, {useEffect, useState} from 'react';
import {Button, makeStyles, TextField, Typography} from "@material-ui/core";
import {useHistory} from 'react-router-dom'
import validateEmail from "../../common/validateEmail";
import axios from "axios";
import {API_GENIAM} from "../../config/apiUrls";
import {MYACCOUNT_URL} from "../../common/constants";
import qs from 'query-string'
import ButtonAppBar from "../GeniamBar/MenuAppBar";

function ForgotPassword(props) {
  const classes = useStyles()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const query = qs.parse(window.location.search)

  useEffect(() => {
    if (query?.email) {
      setEmail(query.email)
    }
    // eslint-disable-next-line
  }, [query?.email])

  const handleCancel = () => {
    history.push('/')
  }
  const onSubmit = async () => {
    if (!email || !validateEmail(email)) {
      setError({
        message: "Invalid email."
      })
      return;
    }
    try {
      setLoading(true)
      await axios.post(`${API_GENIAM}/forgot-password`, {
        accountMigration: false,
        email,
        redirect_url: `${MYACCOUNT_URL}/forgot-password/new-password`
      })
      setIsSubmit(true)
    } catch (e) {
      console.log(e);

    } finally {
      setLoading(false)
    }
  }

  return (
    <div>

      <ButtonAppBar/>
      <div className={classes.root}>
        <Typography className={classes.name}>Geniam</Typography>
        {isSubmit ?
          <React.Fragment>
            <Typography className={classes.title}>パスワード再設定リンクを送りました。メールをご確認ください。</Typography>
            <div className={classes.actions}>
              <Button className={classes.cancelBtn}
                      onClick={handleCancel}
              >
                戻る
              </Button>
            </div>
          </React.Fragment>
          :
          <React.Fragment>
            <Typography className={classes.title}>パスワードを再設定いたします。</Typography>
            <Typography>ご登録の</Typography>
            <TextField
              value={email}
              fullWidth={true}
              onChange={e => setEmail(e.target.value)}
              label={'Email'}
              autoFocus={true}
            />
            {
              error && error.message ?
                <Typography color={"secondary"}>{error.message}</Typography>
                :
                null
            }
            <div className={classes.memo}>
              <Typography>宛に、パスワード再設定のURLをお送りいたします。</Typography>
              <Typography>URLをクリックするとパスワード変更画面が表示されます。</Typography>
            </div>
            <div className={classes.actions}>
              <Button className={classes.cancelBtn}
                      disabled={loading}
                      onClick={handleCancel}
              >
                戻る
              </Button>
              <Button className={classes.acceptBtn}
                      disabled={loading}
                      onClick={onSubmit}
              >
                送信する
              </Button>
            </div>
          </React.Fragment>
        }

      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "90%",
    margin: 'auto',
    borderRadius: 10,
    border: "5px solid",
    padding: 10,
    marginTop: 30,
    maxWidth: 550
  },
  name: {
    fontWeight: 700,
    fontSize: 20,
    textAlign: "center",
    lineHeight: 2
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 2
  },
  memo: {
    marginTop: 20,
    marginBottom: 20
  },
  actions: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center"
  },
  cancelBtn: {
    color: '#fff',
    background: '#AFAFAFFF',
    margin: 5,
    "&:hover": {
      color: '#fff',
      background: '#AFAFAFFF',
      opacity: 0.7
    }
  },
  acceptBtn: {
    color: '#fff',
    background: '#8AC77AFF',
    margin: 5,
    "&:hover": {
      color: '#fff',
      background: '#AFAFAFFF',
      opacity: 0.7
    }
  }
}))

export default ForgotPassword;
