import {addCallback, setGlobal} from 'reactn';
import _ from 'lodash';
import store from 'store';
// Every time the global state changes, this function will execute.
// addCallback(console.log)
const KEY_CART = 'cart.cart';

const getPlanExpDate = plan => {
    if (!plan)
        return null
    let month
    if (plan.includes("month")) {
        //month type
        month = plan.split("month")[0]
        return isNaN(month) ? null : parseInt(month)
    }
    if (plan.includes("year")) {
        //month type
        month = plan.split("year")[0]
        return isNaN(month) ? null : parseInt(month) * 12
    }
    return null
}

let _cart, _seichokuOptions, _couponCodeList;
addCallback(global => {
    const {cart, seichokuOptions, payMethodObj, couponCodeList} = global;
    const cartStore = JSON.parse(localStorage.getItem(KEY_CART));
    if ((cart !== _cart || _seichokuOptions !== seichokuOptions || _couponCodeList !== couponCodeList)) {
        let _totalByProductId = {};
        let _subTotalAll = 0;
        let _taxAll = 0;
        let _totalAll = 0;
        let _genkaAll = 0;
        let _discountAmount = 0;
        let _discount = 0;
        let monthPayPlan = 1
        let monthlyProduct = false;
        let annualProduct = false;
        let amountYear = 0;
        let amountMonthly = 0;
        cartStore?.forEach(c => {
            if (c.product_id) {
                let _seichokuAmount = 0;
                let _total = 0;
                const productSeichokuOpts = seichokuOptions[c.product_id];
                if (c.require_delivery && !_.isEmpty(productSeichokuOpts)) {
                    const checked = productSeichokuOpts.find(o => o.checked);
                    if (checked) {
                        _seichokuAmount = c.num * checked.amount;
                    }
                }
                let subTotal = parseInt(c.amount) * c.num;
                /// check if product has divided times and payMethodObj?.[c.product_id]?.name === '分割'
                if (c.divided_times && payMethodObj?.[c.product_id]?.name === '分割' && c.amount_installment) {
                    subTotal = parseInt(c.amount_installment) * c.num * c.divided_times;
                }
                const genka = Math.round(c.amount * c.num + _seichokuAmount);
                const tax = Math.round(genka * 10 / 100);
                _total = subTotal * 1.1;
                let _totalForDiscount = _total;
                _subTotalAll += _total;

                if (_.isEmpty(payMethodObj[c.product_id])) {
                    _taxAll += tax;
                    _genkaAll += genka;
                    _totalAll += _total;
                    _totalByProductId[c.product_id] = _total;
                } else {
                    if ((payMethodObj?.[c.product_id]?.name === '一括' || payMethodObj?.[c.product_id]?.name === '銀行振込')) {
                        _taxAll += tax;
                        _genkaAll += genka;
                        _totalAll += _total;
                        _totalByProductId[c.product_id] = _total;
                    }
                    if (payMethodObj?.[c.product_id]?.name === '分割') {
                        if (c.amount_installment) {
                            monthPayPlan = c.divided_times;
                            amountMonthly += c.amount_installment * c.num * 1.1;
                            _totalByProductId[c.product_id] = c.amount_installment * c.num * 1.1;
                        } else {
                            monthPayPlan = c.divided_times;
                            amountMonthly += Math.round(_total / c.divided_times);
                            _totalByProductId[c.product_id] = Math.round(_total / c.divided_times);
                        }
                    }
                    if (payMethodObj?.[c.product_id]?.name === "月払い") {
                        monthlyProduct = true;
                        amountMonthly += c.amount_month * c.num * 1.1;
                        monthPayPlan = 12;
                        _totalByProductId[c.product_id] = c.amount_month * c.num * 1.1;
                    }
                    if (payMethodObj?.[c.product_id]?.name === "年払い") {
                        annualProduct = true;
                        amountYear += c.amount_year * c.num * 1.1;
                        _totalByProductId[c.product_id] = c.amount_year * c.num * 1.1;
                    }
                }

                if (couponCodeList) {
                    couponCodeList.forEach(coupon => {
                        let products = coupon.typesUsed.products || [];
                        products.forEach(product_coupon => {
                            if (product_coupon.product_id === c.product_id) {

                                if (product_coupon.couponType === 0) {
                                    _discountAmount = Math.round(
                                        _totalForDiscount * parseInt(product_coupon.couponAmount) /
                                        100);
                                    if (_total - _discountAmount >= 0) {
                                        _total = _total - _discountAmount;
                                        _discount += _discountAmount;
                                    } else {
                                        _total = 0;
                                        _discount = _totalForDiscount;
                                    }
                                }

                                if (product_coupon.couponType === 1) {
                                    if (_total - parseInt(product_coupon.couponAmount) >= 0) {
                                        _total = _total - parseInt(product_coupon.couponAmount);
                                        _discount += parseInt(product_coupon.couponAmount);
                                    } else {
                                        _total = 0;
                                        _discount = _totalForDiscount;
                                    }
                                }
                            }
                        });
                    });
                }

            } else {
                if (!_.isEmpty(payMethodObj)) {
                    const cartPackages = JSON.parse(localStorage.getItem('cart.packages'));
                    let idx = _.findIndex(cartPackages, {'id': c.package_id});
                    if (idx !== -1) {
                        let packageInfo = cartPackages[idx];
                        let _total = 0;
                        let unitPrice;
                        let numOfTax = 10 / 100;
                        const fee = 8.16;
                        let subTotal = parseInt(packageInfo.price) * c.num;
                        let taxTotal = Math.round(subTotal * numOfTax);
                        let package_fee = 0;
                        let feePerMonth = 0;
                        let total = subTotal + taxTotal;
                        let beforeTax = 0;
                        let tax = 0;
                        let firstTemp = 0;
                        const month = getPlanExpDate(packageInfo.planExpDate)
                        if ((payMethodObj[c.package_id].name === '一括' || payMethodObj[c.package_id].name === '銀行振込')) {
                            beforeTax = subTotal;
                            tax = taxTotal;
                            unitPrice = total;
                            firstTemp = 0;
                            feePerMonth = 0;
                            _total = Math.round(beforeTax + tax + feePerMonth + firstTemp);
                            _totalAll += _total;
                            _subTotalAll += _total;
                        }

                        if (payMethodObj[c.package_id].name === '分割') {
                            const dividedTimes = Number(payMethodObj[c.package_id].divided_times);
                            const oneTimePay = Number(payMethodObj[c.package_id]?.prices || payMethodObj[c.package_id]?.price);
                            beforeTax = Math.round(oneTimePay * c.num);
                            tax = Math.round(beforeTax * numOfTax);

                            unitPrice = beforeTax + tax;
                            amountMonthly += unitPrice;
                            firstTemp = 0;
                            package_fee = 0;
                            feePerMonth = 0;
                            monthPayPlan = dividedTimes
                            _total = Math.round(beforeTax + tax + feePerMonth + firstTemp);
                            _subTotalAll += _total;
                        }

                        if (payMethodObj[c.package_id].name === '月々払い') {
                            if (month) {
                                beforeTax = Math.round((parseInt(c.amount) / parseInt(month) * c.num));
                                tax = Math.round(beforeTax * numOfTax);
                                unitPrice = beforeTax + tax;
                                firstTemp = unitPrice;

                                package_fee = total * fee / 100;
                                feePerMonth = Math.round(package_fee / parseInt(month));
                                monthPayPlan = month
                                amountMonthly += unitPrice;
                                _total = Math.round(beforeTax + tax);
                                _subTotalAll += _total;
                            } else {
                                beforeTax = Math.round(packageInfo.price);
                                tax = Math.round(beforeTax * numOfTax);
                                _total = Math.round(beforeTax + tax);
                                _totalAll += _total;
                                _subTotalAll += _total;
                            }
                        }
                        let _totalForDiscount = _total;
                        _totalByProductId[c.package_id] = _total;
                        _taxAll += tax;
                        _genkaAll += beforeTax;
                        if (couponCodeList) {
                            couponCodeList.forEach(coupon => {
                                let packages = coupon.typesUsed.packages || [];
                                packages.forEach(coupon_package => {
                                    let couponAmount = coupon_package.couponAmount;
                                    if (coupon_package.packageID === c.package_id) {
                                        if (coupon_package.couponType === 0) {
                                            let discount = Math.round(
                                                _totalForDiscount * couponAmount / 100);
                                            if (_total - discount >= 0) {
                                                _total = _total - discount;
                                                _discount += discount;
                                            } else {
                                                _total = 0;
                                                _discount = _totalForDiscount;
                                            }
                                        }
                                        if (coupon_package.couponType === 1) {
                                            if (payMethodObj[c.package_id].name === '分割') {
                                                const timesPay = Number(payMethodObj[c.package_id].times);
                                                couponAmount = Math.ceil(couponAmount / timesPay);
                                            }
                                            if (_total - parseInt(couponAmount) >= 0) {
                                                _total = _total - parseInt(couponAmount);
                                                _discount += parseInt(couponAmount);
                                            } else {
                                                _total = 0;
                                                _discount = _totalForDiscount;
                                            }
                                        }
                                    }
                                });
                            });
                        }

                    }
                }
            }
        });
        _cart = cart;
        _seichokuOptions = seichokuOptions;
        _couponCodeList = couponCodeList;
        return {
            totalByProductId: _totalByProductId,
            subTotalAll: _subTotalAll,
            taxAll: _taxAll,
            genkaAll: _genkaAll,
            totalAll: _totalAll,
            discountAll: _discount,
            cart: _cart,
            monthPayPlan,
            amountMonthly,
            monthlyProduct,
            annualProduct,
            amountYear
        };
    }
    return null;
});

setGlobal(
    {
        cart: store.get('cart.cart') || [],
        seichokuOptions: store.get('seichokuOptions') || {},
        taxAll: 0,
        genkaAll: 0,
        totalAll: 0,
        subTotalAll: 0,
        amountYear: 0,
        amountMonthly: 0,
        totalByProductId: {},
        discountAll: 0,
        getServices: [],
        user: {},
        packageID: '',
        packagePrice: 0,
        subFirstInstallment: 0,
        cartPackage: [],
        servicesList: [],
        packages: [],
        totalPrice: [],
        payMethodList: [],
        payMethodObj: {},
        couponCodeList: [],
        couponsCodeList: [],
        appCodeAdded: [],
        langDefault: 'ja-jp',
        monthPayPlan: 1,
        monthlyProduct: false,
        annualProduct: false,
        methodSelect: '一括'
  },
);
