import React, {useEffect} from 'react';
import qs from 'query-string';
import axios from 'axios';
import {setCookie} from '../../common/cookies';
import {LinearProgress} from '@material-ui/core';
import {toast} from 'react-toastify';
import {socialLogin} from './SocialLogin';
import {ACCESS_TOKEN_KEY, API_GENIAM, GENIAM_REFRESH_TOKEN,} from '../../config/apiUrls';

function AuthenticationCode(props) {
  useEffect(() => {
    const query = qs.parse(window.location.search);
    if (query?.code) {
      verifyCode(query.code);
    } else {
      if (query.error_description && query.error_description.includes("Already found an entry for username")) {
        // first sync social email to geniam user email -> need login again to get data
        let provider = ""
        if (query.error_description.includes("Google_")) {
          provider = "Google"
        } else {
          provider = "Facebook"
        }
        toast.success(`${provider} login Already found an email. Login again to get data ...`)
        setTimeout(() => {
          socialLogin(provider)
        }, 2000)
      } else {
        window.location.href = '/'
      }

    }
  }, [])

  const verifyCode = async (code) => {
    try {
      // console.log(process.env);
      const redirect_uri = process.env.REACT_APP_COGNITO_REDIRECT_URL
      // const authDomain = `https://${process.env.REACT_APP_COGNITO_DOMAIN_NAME}/oauth2/token`
      const bodyData = {
        redirect_uri,
        code
      }
      const {data} = await axios.post(`${API_GENIAM}/signin-social`, bodyData)
      // removeAllTokens()
      localStorage.setItem(ACCESS_TOKEN_KEY, data.access_token)
      setCookie(GENIAM_REFRESH_TOKEN, data.refresh_token)
      window.location.href = '/'
    } catch (e) {
      console.log(e);
      toast.error(e.toString())
      window.location.href = '/'
    } finally {
    }
  }
  return (
    <div>
      <LinearProgress/>
    </div>
  );
}

export default AuthenticationCode;
