import React, {useEffect, useState} from 'react';
import getPayMethodProductGeneral from "../common/getPayMethodProductGeneral";
import {setGlobal, useGlobal} from "reactn";
import {Button, Grid} from "@material-ui/core";
import _ from "lodash";
import store from "../store";

function SelectMethodView({onCallback, handleChangeMethod, uid}) {
    const [methodSelect] = useGlobal('methodSelect');
    const [payMethodObj] = useGlobal('payMethodObj');
    const [totalPrice] = useGlobal('totalPrice');
    const [cart] = useGlobal('cart');
    const [generalPayMethod, setGeneralPayMethod] = useState([]);

    useEffect(() => {
        if (!uid && !cart?.length)
            return;

        setGeneralPayMethod(getPayMethodProductGeneral(cart, uid));
        // eslint-disable-next-line
    }, [uid, cart]);

    const getPayMethod = (value, cartView) => {
        const packagePrice = cartView?.product_id ? totalPrice[cartView.product_id] : totalPrice[cartView.package_id];

        let idx = _.findIndex(packagePrice, {'name': value});
        if (idx !== -1) {
            if (cartView.product_id) {
                payMethodObj[`${cartView.product_id}`] = packagePrice[idx];
                onCallback({...packagePrice[idx]});
            }
            if (cartView.package_id) {
                payMethodObj[`${cartView.package_id}`] = packagePrice[idx];
                onCallback({...packagePrice[idx]});
            }
            return payMethodObj;
        }
    };

    const handleChange = (name) => {
        if (name === methodSelect)
            return;

        if (name === '銀行振込') {
            store.selectedPaymentId = 'transferBanking';
            store.store();
            if (_.isFunction(handleChangeMethod))
                handleChangeMethod('transferBanking');
        }

        if (name === '分割' || name === "月払い" || name === "年払い") {
            /// if year pay, remove store.selectedPaymentId = transferBanking
            if (store.selectedPaymentId === 'transferBanking') {
                console.log(store.paymentMethods)
                store.selectedPaymentId = store.paymentMethods[0].id || '';
                store.store();
                if (_.isFunction(handleChangeMethod))
                    handleChangeMethod('');
            }
        }

        let newPayMethodObj = {};
        let initCart = [];

        cart.forEach((item) => {
            newPayMethodObj = getPayMethod(name, item);
            if (name === '一括')
                item.method = 'paynow';
            if (name === '分割' || name === "月払い" || name === "年払い")
                item.method = 'yearpay';
            if (name === '銀行振込')
                item.method = 'transferBanking';

            initCart.push({...item});
        })

        setGlobal({
            methodSelect: name,
            payMethodObj: {...newPayMethodObj},
            cart: [...initCart]
        });
    };

    return (
        <Grid container spacing={2}>
            {
                generalPayMethod && generalPayMethod.map((pm, index) => {
                    return (
                        <Grid item xs={6} key={index}>
                            <Button
                                variant={pm.name === methodSelect ? 'contained' : "outlined"}
                                size="large"
                                onClick={() => handleChange(pm.name)}
                                fullWidth
                                style={{
                                    border: 'solid 2px #0576fe',
                                    borderRadius: '0px',
                                    background: pm.name === methodSelect ? '#0576fe' : "#fff",
                                    color: pm.name === methodSelect ? '#fff' : "#000"
                                }}
                            >
                                {pm.label}
                            </Button>
                        </Grid>

                    );
                })
            }
        </Grid>
    );
}

export default SelectMethodView;
