import {createStyles, Table, TableBody, TableCell, TableRow, Typography, withStyles,} from '@material-ui/core';
import React, {useGlobal} from 'reactn';
import currencyFormat from '../common/currencyFormat';
import useIntlId from './hooks/useIntlId';
import store from '../store';
import {isPayYearOne} from "../common/cart";

const tableStyles = (theme) => createStyles({
    body: {
        fontSize: 16,
        padding: theme.spacing(),
    },
});

const CustomTableCell = withStyles(tableStyles)(TableCell);

const styles = (theme) => createStyles({
    bold: {
        fontWeight: 'bold',
        fontSize: 15,
    },
});

function CartSummary2({classes}) {
    const [totalAll] = useGlobal('totalAll');
    const [monthPayPlan] = useGlobal('monthPayPlan');
    const [annualProduct] = useGlobal('annualProduct');
    const [amountMonthly] = useGlobal('amountMonthly');
    const [amountYear] = useGlobal("amountYear");
    const [discountAll] = useGlobal('discountAll');

    const [subTotalText, shipping, discount, feeMonth, includeTax] = useIntlId(
        [
            'cart.cartSummary.subTotal',
            'cart.cartSummary.shipping',
            'cart.cartSummary.discount',
            'cart.feeMonth',
            'home.cart.includeTax',
        ]);

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <Typography style={{fontWeight: "bold", fontSize: "30px"}}>
                        今回のお支払い
                    </Typography>
                </TableRow>
                <TableRow>
                    {
                        totalAll !== 0 &&
                        <>
                            <CustomTableCell>{subTotalText}</CustomTableCell>
                            <CustomTableCell
                                align={'right'}>{currencyFormat.format(totalAll)} {store.calcTotal() ? includeTax : ""}</CustomTableCell>
                        </>
                    }
                </TableRow>
                <TableRow>
                    {
                        amountMonthly !== 0 &&
                        <>
                            <CustomTableCell>{feeMonth}</CustomTableCell>
                            <CustomTableCell align={'right'}>{currencyFormat.format(
                                amountMonthly)} {includeTax}</CustomTableCell>
                        </>
                    }
                </TableRow>
                <TableRow>
                    {
                        amountYear !== 0 &&
                        <>
                            <CustomTableCell>{"Annual"}</CustomTableCell>
                            <CustomTableCell align={'right'}>{currencyFormat.format(
                                amountYear)} {includeTax}</CustomTableCell>
                        </>
                    }
                </TableRow>
                <TableRow>
                    <CustomTableCell>{shipping}</CustomTableCell>
                    <CustomTableCell align={'right'}>{currencyFormat.format(0)}</CustomTableCell>
                </TableRow>

                {
                    discountAll ?
                        <TableRow>
                            <CustomTableCell>{discount}</CustomTableCell>
                            {
                                isPayYearOne(annualProduct) ?
                                    <CustomTableCell style={{color: 'red'}}
                                                     align={'right'}>- {currencyFormat.format(discountAll)} {`× ${monthPayPlan}回`}</CustomTableCell>
                                    :
                                    <CustomTableCell style={{color: 'red'}}
                                                     align={'right'}>- {currencyFormat.format(discountAll)}</CustomTableCell>
                            }
                        </TableRow>
                        : null
                }
            </TableBody>
        </Table>
    );
}

export default withStyles(styles)(CartSummary2);
