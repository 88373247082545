import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App2 from './App2';
import './index.css';
import * as serviceWorker from './registerServiceWorker';
import Store from './store';
import './config/axios'
import './globalData'
import {LanguageProvider} from "./context/LanguageContext";

// localStorage より復帰
Store.restore();
window['store'] = Store

// Store 初期化処理
Store.init().then(() => {
  // SPA 開始
  ReactDOM.render(
    <LanguageProvider>
      <App2/>
    </LanguageProvider>,
    document.getElementById('root') as HTMLElement
  );
  serviceWorker.unregister();
});

