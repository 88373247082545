import {Button, Hidden, Typography} from '@material-ui/core';
import * as React from 'reactn';
import {getGlobal} from 'reactn';
// @ts-ignore
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Cart2 from '../component/Cart2';
import Footer from '../component/Footer';
import InformationBar from '../component/InformationBar';
import store from '../store';
import ICartItem from '../store/ICartItem';
import FormattedMessage from "../context/FormatedMessage";
import CartCoupons from "../component/CartCoupons";
import LoginCart from "../component/Auth/Login";
import CartSummary2 from "../component/CartSummary2";
import ButtonAppBar from "../component/GeniamBar/MenuAppBar";
import {isEmpty} from "lodash";
import Register from "../component/Auth/Register";

type Props = RouteComponentProps<{}>;

interface IState {
  cart: ICartItem[];
  login: boolean;
  tab: number,
}

class CartView extends React.Component<Props, IState> {
  public state = {
    cart: store.cart,
    login: store.isLogin(),
    tab: 0,
  }

  public componentDidMount() {
    store.refreshCart().then(() => {
      this.setState({
        cart: store.cart,
        login: store.isLogin(),
        tab: 0,
      });
    });
  }

  handleChangeTab = (newValue: number) => {
    this.setState({
      tab: newValue,
    })
  };

  public render() {
    const {cart, tab} = this.state;
    const {user} = getGlobal()

    return (
      <div>
        <ButtonAppBar/>
        <div className={"cart-view-container"}>
          <InformationBar step={0}/>
          <div className="">
            <div className="">
              <div className="panel mb-20">
                <div className="centering">
                  <Typography variant="h5" style={{margin: "15px", fontSize: 24}}>
                    <FormattedMessage id='home.cartInfo'/>
                  </Typography>
                </div>
                <Cart2
                  cart={cart}
                  editable={false}
                  classes={{stockAlert: ""}}
                  handleDelete
                  handleChangeNum
                  handleChangeMethod={() => {
                  }}
                />
                <div className="right m10">
                  {/*<Button variant="contained" color="primary" onClick={this.handleClickEdit}><FormattedMessage*/}
                  {/*  id='home.buttonEditCart'/></Button>*/}
                  <div style={{fontStyle: "italic", textDecoration: "underline", cursor: "pointer"}}
                       onClick={this.handleClickEdit}>カートの編集
                  </div>
                </div>
              </div>
            </div>
            {

                isEmpty(user) ?
                <div className="loin_logout_header">
                  <div className={"mb-20"}>
                    <div className={'text_tutorial_login'}>
                      以下からログインしてご購入ください。
                    </div>
                    <div style={{marginLeft: 50}}>
                      <Button
                        className={tab === 0 ? "tab-active" : "tab"}
                        onClick={() => this.setState({
                          tab: 0,
                        })}>新規登録</Button>
                      <Button
                        className={tab === 1 ? "tab-active" : "tab"}
                        onClick={() => this.setState({
                          tab: 1
                        })}>ログイン</Button>
                    </div>
                  </div>
                  {
                    tab === 0 ?
                      <Register/>
                      :
                      <LoginCart onChangeTab={this.handleChangeTab}/>
                  }
                </div>
                :
                <div className="flex-sub">
                  <div className="panel">
                    <div className="centering"><Typography variant="h5"
                                                           style={{fontSize: 29, margin: "15px 0"}}><FormattedMessage
                      id='home.subtotal'/></Typography></div>
                    <CartSummary2/>
                    <div className="centering m10">
                      <Button variant="contained" color="primary"
                              size={"large"}
                              style={{background: "#FB8800", fontSize: "18px", width: "100%", marginTop: "10px"}}
                              onClick={this.handleClickNext}
                              disabled={!store.checkStock()}>
                        <FormattedMessage id='home.processing'/>
                      </Button>
                    </div>
                  </div>
                  {
                    window.location.pathname !== "/confirmation" &&
                    <Hidden mdUp>
                      <div className="panel">
                        <CartCoupons/>
                      </div>
                    </Hidden>
                  }
                </div>
            }
            {
              (tab === 1 && !user?.user_id) && <div style={{padding: "12px"}}>
                <h3>【お知らせ】</h3>
                <div>ジニアムは会員システムを新しくいたしました。</div>
                <div>それに伴い全会員が、2023年2月6日以降に初めてログインする際に、パスワードの再設定が必要です。</div>
                <div className={"mb-20"}>ログインができない方はお手数ですが「パスワードをお忘れの方へ」をクリックし、パスワードの再設定をお願いいたします。</div>
                <div>
                  ＊ Google ログインまたは Facebook ログインを使ってご登録された方は、パスワードが設定されていないため、メールアドレスでのログインはできません。ご登録時に使ったログイン方法を選択してください。
                  生田知久Andomウィズダムアート研究所
                </div>
              </div>
            }

          </div>
        </div>
        <Footer/>
      </div>
    );
  }
  private handleClickNext = () => {
    this.props.history.push(store.next());
  }

  private handleClickEdit = () => {
    this.props.history.push('/edit');
  }
}

export default withRouter(CartView);
