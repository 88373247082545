import axios from "axios";
import {refreshToken} from "../actions/user";

//REQUEST
axios.interceptors.request.use(
  (config) => {
    console.log(`---- ${config.method.toUpperCase()} ${config.url} ----`);
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    if (error && error.request) {
      console.log(error);
      console.log(error.request);
    }
    return Promise.reject(error);
  });

// RESPONSE
let refreshTime = 0
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const {status, data} = error.response;
    const codes = ["auth/id-token-expired", "auth/argument-error"]
    if ((status === 401
        || status === 400 || status === 500)
      && (data?.error === "login required" || codes.includes(data?.error?.code))
      && refreshTime < 3
    ) {
      refreshTime++
      const data = await refreshToken();
      if (!data) {
        return Promise.reject(error)
      }
      error.config.headers['Authorization'] = `Bearer ${data.accessToken}`;
      return await axios(error.config);
    }
    return Promise.reject(error);
  }
)

