import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Radio,
} from '@material-ui/core';
import {ControlPoint, Delete} from '@material-ui/icons'
import * as React from 'react';
import {Elements, ReactStripeElements} from 'react-stripe-elements';
import API from '../common/API';
import store from '../store';
import IPaymentMethod from '../store/IPaymentMethod';
import StripeInputDialog from './dialog/StripeInputDialog';
import _ from 'lodash';
import ICartItem from "../store/ICartItem";
import FormattedMessage from "../context/FormatedMessage";
import StripeInputCard from "./StripeInputCard";
import {getGlobal} from "reactn";

interface IProps {
    methods: IPaymentMethod[];
    onSelect: (id: string) => void;
    onUpdatePaymentMethods: () => void;
}

interface IState {
    cart: ICartItem[];
    dialogOpen?: number
    alertOpen: boolean;
    cardOpen: boolean;
    hasDivided: boolean;
    isProcessing: boolean;
    selectMethod?: string;
    deleteMethod?: string;
    errorMessage?: string;
}

type Props = IProps & ReactStripeElements.InjectedStripeProps

class PaymentMethodList extends React.Component<Props, IState> {
    public state = {
        cart: store.cart,
        dialogOpen: 0,
        cardOpen: false,
        alertOpen: false,
        hasDivided: false,
        isProcessing: false,
        selectMethod: '',
        deleteMethod: '',
        errorMessage: '',
        methodsDefaults: [
            {
                type: 'transferBanking',
                id: 'transferBanking',
                brand: <FormattedMessage id='cart.payment.bankTransfer'/>,
                last4: null,
                exp_month: null,
                exp_year: null,
                disable: false
            },
            {
                type: 'applePay',
                id: 'applePay',
                brand: 'Apple Pay',
                last4: null,
                exp_month: null,
                exp_year: null,
                disable: true
                // disable: !store.canMakeRequest || !store.canRequestApple
            },
            {
                type: 'googlePay',
                id: 'googlePay',
                brand: 'Google Pay',
                last4: null,
                exp_month: null,
                exp_year: null,
                disable: true
                // disable: !store.canMakeRequest || !store.canRequestGoogle
            },
        ]
    };

    public componentDidMount() {
        const {methodSelect} = getGlobal();
        let id: string;
        let cardOpen = false;
        if (this.props.methods.length === 0) {
            cardOpen = true;
        }
        let transferCartLength = _.filter(this.state.cart, {is_transfer_banking: 1})
        if (transferCartLength === this.state.cart.length)
            id = 'transferBanking';
        else {
            id = store.getSelectedPaymentMethod() != null ? store.selectedPaymentId : (this.props.methods[0] ? this.props.methods[0].id : null);
        }
        // check if one of product has divided_times > 0
        if (store.selectedPaymentId === 'transferBanking' && methodSelect === "分割") {
            id = this.props.methods[0] ? this.props.methods[0].id : null;
            store.selectedPaymentId = id;
            store.store();
        }
        this.setState({
            selectMethod: id,
            cardOpen: cardOpen
        });
        this.props.onSelect(id);
    }

    public render() {
        const {methods} = this.props;
        return (
            <div>
                {methods.length === 0 ? (
                    <div>
                        <div style={{height: "auto"}} className="right">
                            <Button style={{background: "#fff", color: "#8CC358", padding: "8px 25px 8px 25px"}}
                                    onClick={this.handleOpenDialog} startIcon={<ControlPoint fontSize={"large"}/>}>
                                <FormattedMessage id='cart.payment.registerPaymentMethod'/>
                            </Button>
                        </div>
                        <div
                            style={{marginBottom: "10px", fontSize: 16, position: "relative", display: "flex", alignItems: "center"}}>
                            <div style={{width: 10, height: 10, borderRadius: 10, background: "#8CC358"}}/>
                            <div style={{marginLeft: 5}}>
                                クレジットカード決済
                            </div>
                        </div>
                        <Elements>
                            <StripeInputCard onClose={this.handleCloseDialog}/>
                        </Elements>
                    </div>
                ) : (
                    <div className="right">
                        <Button
                            style={{background: "#fff", color: "#8CC358", fontSize: "16px", padding: "8px 25px 8px 25px"}}
                            onClick={this.handleOpenDialog} startIcon={<ControlPoint fontSize={"large"}/>}>
                            <FormattedMessage id='cart.payment.addPaymentMethod'/>
                        </Button>
                    </div>
                )}
                <Grid container={true}>
                    {
                        _.concat(methods, this.state.methodsDefaults).map((m, idx) => {
                            let lang = store.user.language
                            let transferCartLength = _.filter(this.state.cart, {is_transfer_banking: 1})
                            let isCheck = ((m.id === 'transferBanking' || m.id === 'googlePay' || m.id === 'applePay') && (store.cart.filter(c => c.method === 'yearpay').length !== 0))
                            if (m.disable || (m.id === 'transferBanking' && (transferCartLength.length !== this.state.cart.length)))
                                return null

                            return (
                                <Grid item={true} md={4} sm={6} xs={12} key={m.id}>
                                    <Card
                                        style={{margin: '20px', minHeight: '300px', textAlign: 'center', background: 'rgb(245, 245, 245)'}}>
                                        <CardHeader
                                            action={
                                                <Radio
                                                    checked={this.state.selectMethod === m.id}
                                                    onChange={this.handleChangeSelect}
                                                    value={m.id}
                                                    name="method"
                                                    color="primary"
                                                    disabled={isCheck}
                                                />
                                            }
                                            title={lang === 'en-us' ? "Method of payment" + (idx + 1) : lang === 'vi-vn' ? 'Phương thức thanh toán' + (idx + 1) : '支払い方法' + (idx + 1)}
                                        />
                                        {
                                            m.id === 'applePay' || m.id === 'googlePay' || m.id === 'transferBanking' ?
                                                <CardContent>
                                                    <FormattedMessage
                                                        id='cart.payment.paymentMethod'/><br/>{m.brand}<br/>
                                                    <img style={{height: 100}}
                                                         src={"static/images/cards/" + m.id + ".png"} height={24}
                                                         alt=''/>
                                                </CardContent>
                                                :
                                                <>
                                                    <CardContent>
                                                        <FormattedMessage id='cart.payment.paymentMethodCard'/><br/>
                                                        <img src={"static/images/cards/" + m.brand + ".png"} height={24}
                                                             alt=''/>
                                                        {m.brand} <FormattedMessage
                                                        id='cart.payment.lastFourDigits'/> {m.last4}<br/>
                                                        <FormattedMessage
                                                            id='cart.payment.expirationDate'/> {m.exp_month}/{m.exp_year}
                                                    </CardContent>
                                                    <CardActions>
                                                        <IconButton disabled={this.isDisable(m.id)}
                                                                    onClick={this.handleOpenAlert(m.id)}>
                                                            <Delete/>
                                                        </IconButton>
                                                    </CardActions>
                                                </>
                                        }
                                    </Card>
                                </Grid>
                            )
                        })}
                </Grid>
                <Elements>
                    <StripeInputDialog
                        open={this.state.dialogOpen === 2}
                        onClose={this.handleCloseDialog}
                    />
                </Elements>
                <Dialog
                    open={this.state.alertOpen}
                    onClose={this.handleCloseAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"><FormattedMessage id='home.cart.titleDelete'/></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id='cart.payment.sureToDelete'/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="default" onClick={this.handleCloseAlert}><FormattedMessage
                            id='home.cancel'/></Button>
                        <Button variant="contained" color="secondary" onClick={this.handleDelete}><FormattedMessage
                            id='home.delete'/></Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    private handleSubmit = () => {
        this.setState({
            isProcessing: true
        });

        const stripe = this.props.stripe as ReactStripeElements.StripeProps;
        stripe.createToken({}).then((result) => {
            if (result.error) {
                this.setState({
                    isProcessing: false,
                    errorMessage: result.error.message
                })
            } else {
                (async () => {
                    const token = result.token as stripe.Token;
                    await API.newCard(store.accessToken as string, token.id);
                })();
            }
        });
    }

    private handleChangeSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
        // if (ev.target.value === 'transferBanking' && this.checkTransferBanking().result) {
        //     toast.warn(this.checkTransferBanking().message);
        //     return
        // }
        this.setState({
            selectMethod: ev.target.value
        });
        this.props.onSelect(ev.target.value);
    };
    //
    // private checkTransferBanking = () => {
    //     let check = {result: false, message: ''};
    //     if (this.state.cart.length === 0) {
    //         return check = {result: true, message: 'Cart is empty'};
    //     }
    //     this.state.cart.forEach(c => {
    //         if (c.product_id && (c.is_installment && c.divided_times > 0)) {
    //             return check = {result: true, message: `One of Product not found for Bank Transfer payment ${c.name}`}
    //         }
    //     });
    //     if (store.cart.filter(c => c.method === 'yearpay').length > 0) return check = {result: true, message: 'Invalid Installment pay for Bank Transfer payment'};
    //     return check
    // };

    private isDisable = (idCard: string) => {
        let result = false
        const hasDivided = store && store.hasDivided && store.hasDivided.data ? store.hasDivided.data : [];
        hasDivided.forEach(div => {
            if (div.stripe_card_id_1 === idCard || div.stripe_card_id_2 === idCard || div.stripe_card_id_3 === idCard)
                result = true
        })
        return result
    }

    private handleOpenDialog = () => {
        this.setState({
            dialogOpen: 2
        });
    }

    private handleCloseDialog = (refresh: boolean) => () => {
        this.setState({
            dialogOpen: 0
        });

        if (refresh) {
            this.props.onUpdatePaymentMethods();
        }
    }

    private handleOpenAlert = (cardId: string) => () => {
        this.setState({
            alertOpen: true,
            deleteMethod: cardId
        });
    }

    private handleCloseAlert = () => {
        this.setState({
            alertOpen: false
        });
    }

    private handleDelete = () => {
        (async () => {
            await API.deleteCard(store.accessToken as string, this.state.deleteMethod);
            this.setState({
                alertOpen: false,
                deleteMethod: ''
            });
            this.props.onUpdatePaymentMethods();
        })();
    }
}

export default PaymentMethodList;
