import {
  createStyles,
  IconButton,
  Snackbar,
  SnackbarContent,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Close, Error } from '@material-ui/icons';
import * as React from 'react';

const styles = (theme: Theme) => createStyles({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  }
});

interface IProps {
  message?: string;
  open: boolean;
  onClose: () => void;
}

type Props = IProps & WithStyles<typeof styles>;

class ErrorSnackbar extends React.Component<Props> {

  public render() {
    const { classes, message, open, onClose } = this.props;
    return(
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={onClose}
        autoHideDuration={6000}
      >
        <SnackbarContent
          className={classes.error}
          message={
            <span id="error-snackbar" className={classes.message}>
              <Error />
              {message}
            </span>
          }
          action={
            <IconButton
              color="inherit"
              onClick={onClose}
            >
              <Close className={classes.icon} />
            </IconButton>
          }
        />
      </Snackbar>
    )
  }
}

export default withStyles(styles)(ErrorSnackbar);
