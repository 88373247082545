import React, {useEffect, useState} from 'react';
import {Typography} from '@material-ui/core';
import currencyFormat from '../../common/currencyFormat';
import useIntlId from '../hooks/useIntlId';
import SelectMethodView from "../../view/SelectMethodView";
import moment from "moment";
import _ from "lodash";
import {useGlobal} from "reactn";

function ViewPayMethod2({uid, handleChangeMethod}) {
    const [payPackage, setPayPackage] = useState({});
    const [methodSelect] = useGlobal('methodSelect');
    const [totalPrice] = useGlobal('totalPrice');
    const [packagePrice, setPackagePrice] = useState([]);
    const path = window.location.pathname;

    const [paymentAmount, firstPaymentAmount, nextPaymentAmount, paymentMethod] = useIntlId(
        [
            'cart.viewPayMethod.paymentAmount',
            'cart.viewPayMethod.firstPaymentAmount',
            'cart.viewPayMethod.nextPaymentAmount',
            'cart.payment.method']);

    useEffect(() => {
        if (!uid || !totalPrice)
            return;

        const productPrice = totalPrice[uid];
        if (!productPrice?.length)
            return;

        setPackagePrice([...productPrice]);
        // eslint-disable-next-line
    }, [uid, path, totalPrice]);

    useEffect(() => {
        if (!methodSelect || !packagePrice?.length)
            return;

        let idx = _.findIndex(packagePrice, {'name': methodSelect});
        if (idx !== -1) {
            setPayPackage(packagePrice[idx]);
        }
        // eslint-disable-next-line
    }, [methodSelect, packagePrice]);

    const onCallback = (value) => {
        setPayPackage({...value});
    }

    if (!packagePrice?.length) return null;

    const isAllUpfront = payPackage.name === '一括' || payPackage.name === "銀行振込";

    return (
        <>
            {
                packagePrice && packagePrice.length !== 0 && (window.location.pathname === '/confirmation' || window.location.pathname === '/') ?
                    <div>
                        <Typography>{paymentMethod}</Typography>
                        <div style={{padding: 10}}>
                            {
                                (window.location.pathname === '/confirmation' || window.location.pathname === '/') ?
                                    <SelectMethodView onCallback={onCallback} handleChangeMethod={handleChangeMethod}
                                                      uid={uid}/>
                                    :
                                    null
                            }
                        </div>
                        <div>
                            <div>
                                {
                                    isAllUpfront &&
                                    <Typography>{paymentAmount} : {currencyFormat.format(payPackage.subFirstPay)} </Typography>
                                }

                                {
                                    !isAllUpfront ?
                                        payPackage?.divided_times ?
                                            /// Generate Array from number of payPackage.divided_times
                                            [...Array(payPackage.divided_times + 1)].map((e, i) => {
                                                const nextDay = moment().add(i - 1, 'months').format('MM/DD');
                                                if (i === 0) return <Typography className={'mb-10'}
                                                                                key={uid + payPackage.planExpDate + i}>{payPackage.planExpDate} </Typography>
                                                    if (i === 1) return <Typography
                                                        key={uid + payPackage.planExpDate + i}>初回支払い金額: {` ${nextDay} `} {currencyFormat.format(payPackage.subTotal)}</Typography>

                                                return <Typography
                                                    key={uid + payPackage.planExpDate + i}>次回からの支払い: {` ${nextDay} `} {`${currencyFormat.format(payPackage.subTotal)}`}</Typography>
                                                }
                                            )
                                            :
                                            <>
                                                <Typography>{payPackage.planExpDate} </Typography>
                                                <Typography>{firstPaymentAmount} : {currencyFormat.format(payPackage.subFirstPay)} </Typography>
                                                <Typography> {nextPaymentAmount} : {currencyFormat.format(payPackage.subTotal)}</Typography>
                                            </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </>
    )
        ;
}

export default ViewPayMethod2;
