import {API_URL} from "../config/apiUrls";
import axios from 'axios'

axios.defaults.baseURL = API_URL

const BASE_URL = API_URL
//process.env.REACT_APP_ENV === 'production' ? 'https://apis.geniam.com' : 'https://apis-stg.geniam.com';
/** API: 商品取得 */
export const API_GET_PRODUCT = (productId: string) => BASE_URL + '/v1/cart/products/' + productId;

/** API: 商品取得(yourprice) */
export const API_GET_PRODUCT_YOUR_PRICE = (productId: string) => BASE_URL + '/v1/cart/products/' + productId + '/yourprice';

/** API: ユーザ情報取得 */
export const API_GET_USER_INFO = BASE_URL + '/getuserdata';

/** API: トークン更新 */
export const API_REFRESH = BASE_URL + '/refresh';

/** API: ログアウト */
export const API_LOGOUT = BASE_URL + '/logout';

/** API: お届け先一覧取得 */
export const API_LIST_DELIVERIES = BASE_URL + '/v1/cart/deliveries';

/** API: お届け先新規登録 */
export const API_NEW_DELIVERY = BASE_URL + '/v1/cart/deliveries/new';

/** API: お届け先情報更新、削除 */
export const API_DELIVERY = (recordId: number) => BASE_URL + '/v1/cart/deliveries/' + recordId;

/** API: お支払い情報、cards取得 */
export const API_LIST_CARDS = BASE_URL + '/v1/cart/payment/cards';

/** API: お支払い情報、card登録 */
export const API_NEW_CARD = BASE_URL + '/v1/cart/payment/cards/new';

/** API: お支払い情報、card削除 */
export const API_CARD = (cardId: string) => BASE_URL + '/v1/cart/payment/cards/' + cardId;

/** API: 商品購入 */
export const API_PURCHASE = BASE_URL + '/v1/cart/purchase';
export const API_PURCHASE_PACKAGE = BASE_URL + '/v1/services/purchase';

/** API: ポイント取得 */
export const API_POINTS = BASE_URL + '/v1/cart/points';

/** API: 都道府県一覧取得 */
export const API_GET_PREF = BASE_URL + '/get-pref';

/** GET DIVIDED TIME */

export const API_GET_PAYMENT_DIVIDED = BASE_URL + '/v1/cart/hasDividedPayment'

export const LOGIN_EMAIL: any = BASE_URL + "/login";
