import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import * as React from 'react';
import {Fragment, useEffect, useState} from 'react';
import currencyFormat from '../common/currencyFormat';
import {getGlobal, useGlobal} from 'reactn';
import _, {isEmpty} from 'lodash';
import AppCodeAddedInformation from './AppCodeAddedInformation';
import {createTotalPricePackage} from '../store/acctions/getDataCart';
import useIntlId from './hooks/useIntlId';
import SeichokuOptions from "./SeichokuOptions";
import ViewPayMethod2 from "./Cart2Proccess/ViewPayMethod2";
import {byPlanExpDate, getInstallmentPayment, getServices, getServicesList, isPayYearOne} from "../common/cart";
import {useHistory} from "react-router-dom";

function Cart2({
                   cart,
                   editable,
                   classes,
                   handleDelete,
                   handleChangeNum,
                   handleChangeMethod,
               }) {

    const [openAlert, setOpenAlert] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState({
            product_id: '',
            package_id: '',
            num: 0,
            name: '',
            amount: 0,
            require_delivery: false,
            enable_stock: false,
            stock: 0,
            max_num: 0,
            appList: [],
            addedServiceList: [],
            payMethod: [],
        },
    );

    const [totalByProductId] = useGlobal('totalByProductId');
    const [subTotalAll] = useGlobal('subTotalAll');
    const cartPackages = JSON.parse(localStorage.getItem('cart.packages'));
    const [, setPayMethodList] = useGlobal('payMethodList');
    const [payMethodObj] = useGlobal('payMethodObj');
    const [methodSelect] = useGlobal('methodSelect');
    const [amountYear] = useGlobal("amountYear");
    const [servicesList] = useGlobal('servicesList');
    const {couponCodeList} = getGlobal();
    const [showInstallment, setShowInstallment] = React.useState(null);
    const cartPayment = localStorage.getItem('cart.payment') || null;
    const [cartEmpty, productName, quantity, price, outOfStock, planExpirationDate, includeTax, postage, subtotalTax, titleDelete1, titleDelete2, cancelButton, deleteButton, allTotal12Month] = useIntlId(
        [
            'home.cart.cartEmpty',
            'home.cart.productName',
            'home.cart.quantity',
            'home.cart.price',
            'home.cart.outOfStock',
          'home.cart.planExpirationDate',
          'home.cart.includeTax',
          'home.cart.postage',
          'home.cart.subtotalTax',
          'home.cart.titleDelete',
          'home.cart.titleDelete1',
          'home.cancel',
          'home.delete',
          'cart.allTotal12Month',
        ]);

  const history = useHistory();

  useEffect(() => {
    if (isEmpty(payMethodObj)) return;
    setShowInstallment(getInstallmentPayment(payMethodObj));
    // eslint-disable-next-line
  }, [payMethodObj, totalByProductId]);

  useEffect(() => {
    getServices();
    getServicesList();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getPayMethod();
        createTotalPricePackage();
        // eslint-disable-next-line
    }, [cart, methodSelect]);

    const getPayMethod = () => {
        if (cart && cart.length !== 0) {
            let payList = {};
            cart.forEach(c => {
                let payMethodList = c.payMethod.filter(s => s.selected === true);
                payList[`${c.package_id}`] = payMethodList;
            });
            setPayMethodList(payList);
        }
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const openDeleter = (item) => () => {
        setOpenAlert(true);
        setDeleteTarget(item);
    };

    const handleDoDelete = () => {
        if (handleDelete) {
            if (!_.isEmpty(deleteTarget.product_id)) {
                handleDelete(deleteTarget.product_id)();

            } else if (!_.isEmpty(deleteTarget.package_id)) {
                handleDelete(deleteTarget.package_id)();

            } else {
                handleDelete(deleteTarget.product_id)();

            }
        }
        setOpenAlert(false);
    };

    const getNamePlanTime = (packageID) => {
        try {
            if (packageID) {
                let idxPK = _.findIndex(cartPackages, {'id': packageID});
              if (idxPK !== -1) {
                let indexPlanExpDate = _.findIndex(byPlanExpDate,
                  {'idPlan': cartPackages[idxPK].planExpDate});
                return byPlanExpDate[indexPlanExpDate].namePlan;
              }
            }
        } catch (e) {
          console.log(e.toString());
        }
    };

  const handleClickEdit = () => {
    history.push('/edit');
  }

  if (cart.length === 0) {
    return (
      <div>
        <p className="centering">{cartEmpty}</p>
        {
          couponCodeList && couponCodeList.length !== 0 &&
          <AppCodeAddedInformation
            couponCodeList={couponCodeList}
            servicesList={servicesList}
                    />
                }
            </div>
        );
    } else {
        return (
            <div>
                {
                    window.location.pathname === '/confirmation' && couponCodeList && couponCodeList.length !== 0 &&
                    <div>
                        <AppCodeAddedInformation
                            couponCodeList={couponCodeList}
                            servicesList={servicesList}
                        />
                    </div>
                }

                <Table>
                    <TableHead style={{background: "#F5F5F5"}} size="small" aria-label="a dense table">
                        <TableRow style={{height: 36, background: "#F5F5F5"}}>
                            <CustomTableCell>{productName}</CustomTableCell>
                            <CustomTableCell align={'right'}
                                             style={{padding: '0 auto'}}> </CustomTableCell>
                            <CustomTableCell align={'right'}
                                             style={{padding: '0 auto'}}> </CustomTableCell>
                            <CustomTableCell align={'center'} style={{
                                width: 80,
                                padding: '0 auto',
                            }}>{quantity}</CustomTableCell>
                            <CustomTableCell align={'right'}>{price}</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            _.orderBy(cart, 'amount', 'asc').map((product, idx) => {
                                const planTime = getNamePlanTime(product.package_id);
                                return (
                                    <Fragment key={idx}>
                                        <TableRow style={{height: 36}}>
                                            <CustomTableCell style={{padding: ' 0 10px'}}>
                                                {product.name}
                                                {editable ? (
                                                    <IconButton
                                                        onClick={openDeleter(product)}><Delete/></IconButton>
                                                ) : ''}
                                                {
                                                    product.enable_stock && product.stock < product.num ?
                                                        <span className={classes.stockAlert}><br/>{outOfStock}</span>
                                                        :
                                                        ''
                                                }
                                            </CustomTableCell>

                                            <CustomTableCell>
                                                {
                                                    product.package_id ?
                                                        <div style={{fontSize: 14}}>{planExpirationDate}{planTime}</div>
                                                        :
                                                        ''
                                                }
                                            </CustomTableCell>

                                            <CustomTableCell>

                                            </CustomTableCell>

                                            <CustomTableCell align={'center'}>
                                                {
                                                    editable ?
                                                        product.product_id ?
                                                            <TextField
                                                                type="number"
                                                                value={product.num}
                                                                style={{width: 64}}
                                                                inputProps={{style: {textAlign: 'right'}}}
                                                                onChange={handleChangeNum ? handleChangeNum(product.product_id) : () => {
                                                                }}
                                                            />
                                                            :
                                                            product.package_id ?
                                                                <TextField
                                                                    type="number"
                                                                    value={product.num}
                                                                    style={{width: 64}}
                                                                    inputProps={{style: {textAlign: 'right'}}}
                                                                    onChange={handleChangeNum ? handleChangeNum(product.package_id) : () => {
                                                                    }
                                                                    }
                                                                />
                                                                :
                                                                product.num
                                                        :
                                                        product.num
                                                }
                                            </CustomTableCell>
                                            {
                                                !_.isEmpty(product.package_id) ?
                                                    <>
                                                        <CustomTableCell
                                                          style={{padding: ' 0 10px'}}
                                                          align={'right'}>{currencyFormat.format(totalByProductId[product.package_id])} {includeTax}
                                                        </CustomTableCell>
                                                    </>
                                                  :
                                                  <CustomTableCell
                                                    style={{padding: ' 0 10px'}}
                                                    align={'right'}>{currencyFormat.format(totalByProductId[product.product_id])} {includeTax}
                                                  </CustomTableCell>
                                            }
                                        </TableRow>
                                      {
                                        window.location.pathname === '/confirmation' && <TableRow>
                                          <CustomTableCell className={classes.borderNone}/>
                                          <CustomTableCell className={classes.borderNone}/>
                                          <CustomTableCell className={classes.borderNone}/>
                                          <CustomTableCell className={classes.borderNone}/>
                                          <CustomTableCell
                                            className={classes.borderNone}
                                            style={{padding: ' 10px'}}
                                            align={'right'}>
                                            <div style={{
                                              fontStyle: "italic",
                                              textDecoration: "underline",
                                              cursor: "pointer"
                                            }} onClick={handleClickEdit}>変更
                                            </div>
                                          </CustomTableCell>
                                        </TableRow>

                                      }


                                      {
                                        product.require_delivery && (
                                          <>
                                            <TableRow key={'_' + product.product_id}>
                                              <CustomTableCell colSpan={10}>
                                                <SeichokuOptions product={product}/>
                                              </CustomTableCell>
                                            </TableRow>
                                          </>
                                            )
                                        }
                                        {
                                            (!_.isEmpty(product.package_id)) ?
                                                <TableRow>
                                                    <CustomTableCell style={{paddingRight: 0}} align={'center'}
                                                                     colSpan={5}>
                                                        {
                                                            cartPayment === 'applePay' || cartPayment === 'googlePay' ?
                                                                null
                                                                :
                                                                <>
                                                                    {
                                                                        (
                                                                            window.location.pathname === '/confirmation' || window.location.pathname === '/') ?
                                                                            <ViewPayMethod2 uid={product.package_id}
                                                                                            handleChangeMethod={handleChangeMethod}/>
                                                                            :
                                                                            null
                                                                    }
                                                                </>
                                                        }
                                                    </CustomTableCell>
                                                </TableRow>
                                                :
                                                <TableRow>
                                                    <CustomTableCell style={{paddingRight: 0}} align={'center'}
                                                                     colSpan={5}>
                                                        {
                                                            cartPayment === 'applePay' || cartPayment === 'googlePay' ?
                                                                null
                                                                :
                                                                <>
                                                                    {
                                                                        (
                                                                            window.location.pathname === '/confirmation' || window.location.pathname === '/') ?
                                                                            <ViewPayMethod2 uid={product.product_id}
                                                                                            handleChangeMethod={handleChangeMethod}/>
                                                                            :
                                                                            null
                                                                    }
                                                                </>
                                                        }

                                                    </CustomTableCell>
                                                </TableRow>
                                        }
                                    </Fragment>
                                );
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow style={{height: '36px'}}>
                            <CustomTableCell className={classes.borderNone}
                                             style={{paddingLeft: 10}}
                                             colSpan={4}>{postage}</CustomTableCell>
                            <CustomTableCell align={'right'} className={classes.borderNone}
                                             style={{paddingRight: 10}}
                                             colSpan={4}>{currencyFormat.format(0)}</CustomTableCell>
                        </TableRow>
                        <TableRow>
                            <CustomTableCell className={classes.borderNone}
                                             style={{paddingLeft: 10}}
                                             colSpan={4}>
                                <Typography variant="h4" style={{fontWeight: "bold", fontSize: "16px"}}>
                                    {
                                        showInstallment ?
                                            `合計${showInstallment.divided_times}回のお支払い総額（税込）`
                                            :
                                            (amountYear || isPayYearOne()) ?
                                                allTotal12Month
                                                :
                                                subtotalTax
                                    }
                                </Typography>
                            </CustomTableCell>
                            <CustomTableCell align={'right'} className={classes.borderNone} style={{paddingRight: 10}}
                                             colSpan={4}>{currencyFormat.format(subTotalAll)}
                            </CustomTableCell>
                        </TableRow>
                    </TableFooter>
                </Table>


                <Dialog
                    open={openAlert}
                    onClose={handleCloseAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{titleDelete1}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {deleteTarget.name}{titleDelete2}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="default"
                                onClick={handleCloseAlert}>{cancelButton}</Button>
                        <Button variant="contained" color="secondary"
                                onClick={handleDoDelete}>{deleteButton}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}

const tableStyles = (theme) => createStyles({
    head: {
        backgroundColor: '#f5f5f5',
        color: '#000',
        padding: theme.spacing(),
        fontSize: 16,
        fontWeight: 600
    },
    body: {
        fontSize: 16,
        padding: 0,
    },
    footer: {
        color: '#000000',
        fontSize: 16,
        padding: 0,
    },
});
const CustomTableCell = withStyles(tableStyles)(TableCell);

const styles = (theme) => createStyles({
    stockAlert: {
        fontSize: 14,
        color: '#ff0000',
    },
    borderNone: {
        border: 'none',
    },
});

export default withStyles(styles)(Cart2);
