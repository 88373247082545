import * as React from 'react';
// @ts-ignore
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {injectStripe, PaymentRequestButtonElement, ReactStripeElements} from 'react-stripe-elements';
import store from '../store';
import ErrorSnackbar from './ErrorSnackbar';
import {getGlobal} from "reactn";

type Props = ReactStripeElements.InjectedStripeProps & RouteComponentProps<{}>;

interface IState {
    canMakePayment?: boolean,
    paymentRequest?: stripe.paymentRequest.StripePaymentRequest;
    openSnackbar?: boolean,
    snackbarMessage?: string,
}

class PaymentRequestForm extends React.Component<Props, IState> {
    public state = {
        canMakePayment: false,
        paymentRequest: undefined,
        openSnackbar: false,
        snackbarMessage: '',
    };

    public componentWillMount() {
        const {subTotalAll} = getGlobal();

        if (!subTotalAll || subTotalAll === 0)
            return;

        const stripe = this.props.stripe as ReactStripeElements.StripeProps;
        const paymentRequest = stripe.paymentRequest({
            country: 'JP',
            currency: 'jpy',
            total: {
                amount: Math.round(subTotalAll),
                label: 'お支払い合計'
            },
            requestShipping: store.isRequireDelivery(),
            shippingOptions: [
                {
                    id: 'include-shipping',
                    label: '配送料込',
                    detail: 'お支払い金額に配送料が含まれています。',
                    amount: 0
                }
            ],
            jcbEnabled: true,
        });

        paymentRequest.on('token', (response) => {
            const source = response.token.id;
            const address = response.shippingAddress ? {
                address_name: response.shippingAddress.recipient,
                address_zip: response.shippingAddress.postalCode,
                address_country: response.shippingAddress.country,
                address_state: response.shippingAddress.region,
                address_city: response.shippingAddress.city,
                address_street: response.shippingAddress.addressLine.join(' '),
                address_building: '',
                tel: response.shippingAddress.phone
            } : null;
            store.doPaymentRequest(source, address)
                .then((json) => {
                    response.complete('success');
                    store.setThanks(json, []);
                    this.props.history.push('/thanks');
                })
                .catch((error) => {
                    response.complete('fail');
                    this.setState({
                        openSnackbar: true,
                        snackbarMessage: error.message,
                    });
                })
        });

        paymentRequest.canMakePayment().then((result) => {
            this.setState({
                canMakePayment: !!result && store.checkStock()
            })
            store.canMakeRequest = !!result && store.checkStock()
            if (result) {
                if (result["applePay"] === false) {
                    store.canRequestApple = false
                }
                if (result["applePay"] === true) {
                    store.canRequestApple = true
                    store.canRequestGoogle = false
                }
            }
        });

        this.setState({
            canMakePayment: false,
            paymentRequest
        });
    }

    public render() {
        return this.state.canMakePayment && window.location.pathname === "/confirmation" && (
            store.selectedPaymentId === "googlePay" || store.selectedPaymentId === "applePay"
        ) ? (
            <div>
                <PaymentRequestButtonElement
                    paymentRequest={this.state.paymentRequest}
                />
                <ErrorSnackbar
                    open={this.state.openSnackbar}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />
            </div>
        ) : null;
    }

    private handleCloseSnackbar = () => {
        this.setState({
            openSnackbar: false
        });
    }
}

// @ts-ignore

export default withRouter(injectStripe(PaymentRequestForm));
