import {db} from '../firebaseConfig'
import moment from "moment";
import {isProductionEnv} from "../config/env";
import {toast} from "react-toastify";

export const collectionCodes = isProductionEnv ? 'codes' : 'codes-stg'
export const collectionCoupon = isProductionEnv ? 'coupons' : 'coupons-stg'

export const getCouponsInfo = async (coupons, userID) => {
  try {
    if (coupons.length === 0)
      return []
    let data = await Promise.all(coupons.map(async cp => {
      const codeSnap = await db.collection(collectionCodes)
        .doc(cp)
        .get()
      if (!codeSnap.exists) {
        toast.error('Code error! Please check again')
        return null
      }
      const codeData = codeSnap.data()
      // time validate
      if (!codeData.isUnlimited && moment().isAfter(codeData.endDate)) {
        toast.error('Code has expired')
        return null
      }
      // user validate
      const userSnap = await db.collection(collectionCodes)
        .doc(cp)
        .collection('users')
        .get()
      if (userSnap.size >= codeData.numOfUses) {
        toast.error('Code has limited')
        return null
      }
      let usedThisCode = 0
      userSnap.forEach(doc => {
        let data = doc.data()
        if (data.user_id === userID)
          usedThisCode++
      })
      let usedByUser = codeData.usedByUser || 1
      if (usedThisCode >= usedByUser) {
        toast.error(`This code used ${usedThisCode} times. Try another code , plesase.`)
        return null
      }
      return {coupon_id: codeData.couponID, code_id: cp}
    }))
    data = data.filter(x => x)

    let couponsData = await Promise.all(data.map(async d => {
      const snap = await db.collection(collectionCoupon)
        .doc(d.coupon_id)
        .get()
      if (!snap.exists)
        return null
      return {...snap.data(), id: snap.id, code_id: d.code_id}
    }))
    couponsData = couponsData.filter(x => x)
    return couponsData
  } catch (e) {
    toast.error('Code error! Please check again')
    console.log(e.toString())
  }
}

export function getBonusInfo(serviceList, coupons) {
  let result = {
    ticketable: false,
    numOfTickets: 0,
    coinable: false,
    numOfCoins: 0
  }
  serviceList.forEach(serv => {
    if (serv.ticketable && serv.numOfTicket && parseInt(serv.numOfTicket) > 0) {
      result.numOfTickets += parseInt(serv.numOfTicket)
      result.ticketable = true
    }
    if (serv.coinable && serv.numOfCoin && parseInt(serv.numOfCoin) > 0) {
      result.numOfCoins += parseInt(serv.numOfCoin)
      result.coinable = true
    }
  })
  coupons.forEach(coupon => {
    if (coupon.ticketable && coupon.numOfTickets && parseInt(coupon.numOfTickets) > 0) {
      result.numOfTickets += parseInt(coupon.numOfTickets)
      result.ticketable = true
    }
    if (coupon.coinable && coupon.numOfTickets && parseInt(coupon.numOfTickets) > 0) {
      result.numOfCoins += parseInt(coupon.numOfTickets)
      result.coinable = true
    }
  })
  return result
}
