import React, {useEffect, useState} from 'react';
import {
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemSecondaryAction,
  Popover,
  TextField,
  Typography
} from '@material-ui/core'
import {AccountCircle, KeyboardArrowDown, Lock, Mail, Visibility, VisibilityOff} from '@material-ui/icons'
import {setGlobal, useGlobal} from 'reactn'
import {makeStyles} from "@material-ui/core/styles";
import validateEmail from "../../common/validateEmail";
import {signInEmailPassword} from "../../common/signInEmailPassword";
import Store from '../../store/index';
import {setCookie} from "../../common/cookies";
import {ACCESS_TOKEN_KEY, GENIAM_REFRESH_TOKEN} from "../../config/apiUrls";
import SocialLogin from "./SocialLogin";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import API from "../../common/API";
import store from "store";
import {Initial} from "react-initial";
import ListItemText from "@material-ui/core/ListItemText";
import {ACCOUNTS_KEY} from "../../config/constant";
import List from "@material-ui/core/List";

function LoginCart({onChangeTab}) {
  const classes = useStyles()
  const history = useHistory()
  const [email, setEmail] = useState(store.get('last_login') || '')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const [language] = useGlobal('langDefault');
  const [loading, setLoading] = useState(false)
  const [mfaSent, setMfaSent] = useState(false)
  const [login, setLogin] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accounts, setAccounts] = useState([])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);


  useEffect(() => {
    getAccountInfos()
  }, [])

  async function getAccountInfos() {
    const accountsList = store.get(ACCOUNTS_KEY) || []
    const accountInfos = await Promise.all(accountsList.map(
      async email => {
        try {
          const res = await API.loginEmailAsync(email);
          if (res?.email)
            return res
          return {email: email}
        } catch (e) {
          console.log(e)
        } finally {
          setLoading(false)
        }
      }))

    setAccounts(accountInfos)
  }

  const onEmailChange = (e) => {
    setEmail(e.target.value)
    setErrors({})
  }
  const onPasswordChange = (e) => {
    setPassword(e.target.value)
    setErrors({})
  }

  const onLogin = () => {
    if (!email) {
      setErrors({
        email: "email required"
      })
      return;
    }
    if (!validateEmail(email)) {
      setErrors({
        email: "invalid email"
      })
      toast.error("Invalid email");
      return;
    }
    if (!password) {
      setErrors({
        password: "password required"
      })
      toast.error("Password required");
      return;
    }
    handleLogin()
  }
  const onKeyDown = (name) => (event) => {
    if (event.key === 'Enter') {
      if (name === 'email') {
        if (!email) {
          return;
        }
        if (!validateEmail(email)) {
          setErrors({
            email: "invalid email"
          })
          return;
        }
        onNext()
        return;
      }
      if (name === 'password') {
        if (!password) {
          return;
        }
        if (password?.length < 6) {
          setErrors({
            password: "at least 6 characters"
          })
          return;
        }
        handleLogin()
      }
    }
  }

  const handleLogin = async () => {
    if (!email || !password || !validateEmail(email))
      return;
    try {
      setLoading(true)
      setMfaSent(false)
      const user = await signInEmailPassword(email, password)
      // mfa check
      setCookie(GENIAM_REFRESH_TOKEN, user.stsTokenManager.refreshToken)
      localStorage.setItem(ACCESS_TOKEN_KEY, user.stsTokenManager.accessToken)
      await Store.init()
      setGlobal({
        user: Store.user
      })
      toast.success("Login successful!");
    } catch (e) {
      const message = e?.response?.data?.error_message
      if (message) {
        setErrors({
          password: message
        })
        toast.error(message)
      } else if (e.message) {
        toast.error(e.message)
      } else {
        toast.error("Something was wrong!");
      }
    } finally {
      setLoading(false)
    }
  }

  const forgotPassword = () => {
    if (email && validateEmail(email)) {
      history.push(`/forgot-password?email=${email}`)
      return
    }
    history.push("/forgot-password")
  }

  const onNext = async (e) => {
    e?.preventDefault();

    if (!validateEmail(email)) {
      setErrors({
        email: "メールアドレスが不正です。"
      });
      return;
    }

    setLoading(true)
    const res = await API.loginEmailAsync(email);
    if (res) {
      setLogin(res)
      setEmail(res?.email)
    } else {
      setErrors({login: "アカウントは有効ではありません。"})
    }
    setLoading(false)
  };
  const onAccountSelect = (account) => {
    setEmail(account?.email)
    setLogin(account)
    setAnchorEl(null)
  }
  const handleOtherAccount = () => {
    setLogin(null)
    setEmail('')
    setAnchorEl(null)
  }
  const handleNewAccount = () => {
    setLogin(null)
    setEmail('')
    setAnchorEl(null)
    onChangeTab(0)
  }
  return (
    <div className={classes.root}>

      <div>
        <img className={classes.logo} alt={'logo'} src={'/static/images/logo.svg'}/>
      </div>
      <SocialLogin/>

      {
        !login?.email ?
          <div className={classes.group}>
            <TextField
              id="email"
              label="Email"
              className={classes.input}
              value={email}
              size={"small"}
              variant="outlined"
              onChange={onEmailChange}
              onKeyDown={onKeyDown("email")}
              helperText={errors && (errors.login || errors.email) ? (errors.login || errors.email) : null}
              error={!!(errors && (errors.login || errors.email))}
            />
            <Button
              className={classes.button}
              onClick={onNext}
              variant={'contained'}
              color={'primary'}
              disabled={loading}
            >
              ログイン
            </Button>
          </div>
          :
          <div>
            <ListItem className={classes.infoLogin}>
              {
                login?.avatar ? <Avatar
                    style={{width: 60, height: 60}}
                    src={login?.avatar}/> :
                  <div className={"account_avatar_div"}>
                    <Initial
                      name={login?.last_name + login?.first_name}
                      height={60}
                      width={60}
                      fontSize={30}
                      textColor={"#8AC77A"}
                      color={'#fff'}
                    />
                  </div>
              }
              <ListItemText
                style={{padding: 0, marginLeft: 10}}
                className={"ml10"}
                primary={`${login?.last_name || ''} ${login?.first_name || ''}`}
                secondary={login?.email}/>
              <ListItemSecondaryAction>
                <IconButton aria-label="other_account"
                            onClick={(e) => handleClick(e)}>
                  <KeyboardArrowDown color="action" style={{fontSize: 40}}/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            <div className={classes.group}>
              <TextField
                id="password"
                className={classes.input}
                value={password}
                type={showPassword ? 'text' : 'password'}
                margin="normal"
                placeholder={language === 'ja-jp' ? "パスワードを入力してください" : "Enter your password"}
                onChange={onPasswordChange}
                onKeyDown={onKeyDown("password")}
                helperText={errors && errors.password ? errors.password : null}
                error={!!(errors && errors.password)}
                variant="outlined"
                label={"パスワード"}
                size={"small"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock/>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                className={classes.button}
                onClick={onLogin}
                variant={'contained'}
                color={'primary'}
                disabled={loading}
              >
                ログイン
              </Button>
            </div>
            <Button className={classes.forgot}
                    onClick={forgotPassword}
            >
              パスワードをお忘れの方はこちらへ
            </Button>
          </div>
      }
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List className={"w100"}>
          {accounts.map((account, index) => {
            if (account === email) return null
            let avatar, name;
            avatar = account.avatar;
            name = `${account.last_name || ''} ${account.first_name || ''}`;
            return <ListItem
              key={account?.email}
              style={{paddingLeft: 0, paddingRight: 0}}
              onClick={() => onAccountSelect(account)}
              disabled={loading}
            >
              <Button
                variant={"text"}
                fullWidth={true}
                className="w100"
                style={{textAlign: 'left', textTransform: 'none'}}>
                {avatar ? <Avatar
                    style={{width: 50, height: 50}}
                    src={avatar}/> :
                  <div className={classes.avatar}>
                    <Initial
                      name={name}
                      height={50}
                      width={50}
                      fontSize={24}
                      radius={100}
                      textColor={"#8AC77A"}
                      color={'#fff'}
                    />
                  </div>
                }
                <ListItemText
                  primary={name}
                  secondary={account?.email}
                />
              </Button>
            </ListItem>
          })
          }
          <ListItem
            key={"他のアカウントでログイン"}
            onClick={handleOtherAccount}
          >
            <Button variant={"text"}
                    fullWidth={true}
                    className="w100"
                    style={{textAlign: 'left', textTransform: 'none'}}>
              <Mail style={{marginRight: 5}}/>
              <ListItemText primary={"他のアカウントでログイン"}/>
            </Button>
          </ListItem>
          <ListItem
            key={"新規登録"}
            onClick={handleNewAccount}
          >
            <Button variant={"text"} fullWidth={true} className="w100"
                    style={{textAlign: 'left', textTransform: 'none'}}>
              <AccountCircle style={{marginRight: 5}}/>
              <ListItemText primary={"新規登録"}/>
            </Button>
          </ListItem>
        </List>
      </Popover>
      {
        mfaSent &&
        <Typography>メールをご確認ください。</Typography>
      }

      {/*<Button*/}
      {/*  className={`btnPrimary ${classes.button}`}*/}
      {/*  fullWidth={true}*/}
      {/*  onClick={onLogin}*/}
      {/*  disabled={loading}*/}
      {/*>*/}
      {/*  ログインして購入*/}
      {/*</Button>*/}

    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    background: "#fff",
    padding: "25px 50px",
    borderRadius: "8px",
    "& .MuiListItem-container": {
      listStyle: "none",
    }
  },
  title: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: 16
  },
  serviceName: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 2.5
  },
  button: {
    // color: "rgb(138, 199, 122)"
    // fontSize: 20
    marginLeft: 15,
    height: 40
  },
  forgot: {
    margin: "20px 0",
    color: "#8AC77AFF"
  },
  logo: {
    display: "block",
    margin: "10px auto 30px auto"
  },
  group: {
    display: "flex",
    alignItems: "flex-start"
  },
  input: {
    flex: 1,
    margin: 0
  },
  infoLogin: {
    listStyle: "none",
    marginBottom: 20,
    padding: 0
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: "50%",
    overflow: "hidden"
  }
}))

export default LoginCart;
